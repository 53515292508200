import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { connect } from "react-redux";
import {
  setUser,
  setAlert,
  setTemp,
  setGallery,
  setImage,
  setExif,
  setIptc,
  setMeta,
} from "../actions";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography, IconButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useTheme, Grid, FormControlLabel } from "@mui/material";
//import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import TagIcon from "@mui/icons-material/LocalOffer";

const useStyles = makeStyles((theme) => ({
  button: {
    "&:hover": {
      color: theme.palette.highlight.main,
    },
  },
}));

function ImageCard(props) {
  const [approved, setApproved] = React.useState(props.img.approved);
  const [deleted, setDeleted] = React.useState(false);
  const [borderBottom, setBorderBottom] = React.useState("");

  var checked = props.selected.includes(props.img._id);
  const theme = useTheme();

  var classes = useStyles();

  React.useEffect(() => {
    if (props.img.color) {
      var bottom_string = "4px solid ";
    } else {
      bottom_string = "";
    }

    if (props.img.color === "red") {
      setBorderBottom(bottom_string + "#ff0000");
    }

    if (props.img.color === "green") {
      setBorderBottom(bottom_string + "#00ff00");
    }

    if (props.img.color === "blue") {
      setBorderBottom(bottom_string + "#0000ff");
    }

    if (props.img.color === "yellow") {
      setBorderBottom(bottom_string + "#ffff00");
    }

    if (props.img.color === "magenta") {
      setBorderBottom(bottom_string + "#ff00ff");
    }

    if (props.img.color === "none") {
      setBorderBottom("none");
    }

    if (props.img.approved !== approved) {
      setApproved(props.img.approved);
    }
  }, [props.img.color, setApproved, approved, props.img.approved]);

  if (deleted) {
    return null;
  }

  /*
  return props.img.data.thumbnailURL.split("/")[
    props.img.data.thumbnailURL.split("/").length - 1
  ];
*/
  const handleDownload = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = imageUrl.split("/")[imageUrl.split("/").length - 1];
      link.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.cards.main,
      }}
    >
      <CardContent
        sx={{
          padding: 0,
          margin: 0,
          flexDirection: "row",
          display: "flex",
        }}
      >
        <FormControlLabel
          sx={{
            width: "100%",
            padding: 0,
            margin: "0px !important",
            overflow: "hidden",
          }}
          control={
            <Checkbox
              coltrueor="custom"
              checked={checked}
              onChange={() => {
                props.toggleSelected(props.img._id);
              }}
              disableRipple={true}
            />
          }
          label={<Typography variant="caption">{props.img.title}</Typography>}
        />

        {props.admin && !props.viewer ? (
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              var temp = Object.assign({}, props.temp);
              temp.confirm = {
                title: "Are you sure?",
                cancel_text: "No",
                text: "Do you want to delete this photo?",
                confirm_text: "Yes",
                callback_color: "error",
                callback: () => {
                  props.deletePhoto(props.img._id);
                  setDeleted(true);
                },
              };
              props.setTemp(temp);
            }}
            variant="contained"
            size="small"
            color="button"
            className={classes.button}
          >
            <DeleteIcon />
          </IconButton>
        ) : null}
      </CardContent>
      <CardContent sx={{ padding: 0 }}>
        <div
          className="edit-holder"
          onClick={() => {
            props.setImage({
              image: props.img,
              total: props.total,
              idx: props.idx,
            });
          }}
        >
          <div
            className="open-edit-image"
            crossOrigin="anonymous"
            style={{
              background:
                'url("' + props.image.replace(/\+/g, "%2B") + '")no-repeat',
            }}
          />
        </div>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 0,
          borderBottom: borderBottom,
        }}
      >
        <Grid container>
          <Grid item xs={3}>
            {!props.viewer ? (
              <IconButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={async () => {
                  var status =
                    approved === true
                      ? false
                      : approved === false
                      ? "reset"
                      : false;
                  var _gallery = Object.assign({}, props.gallery);

                  _gallery.documents = _gallery.documents.map((g, i) => {
                    var _g = Object.assign({}, g);
                    if (g._id === props.img._id) {
                      _g.approved = status === "reset" ? "" : status;
                    }
                    return _g;
                  });

                  props.setGallery(_gallery);

                  await props.toggleApprove(
                    approved === true
                      ? false
                      : approved === false
                      ? "reset"
                      : false,
                    props.img._id
                  );

                  //setApproved(status === "reset" ? "" : status);
                }}
                variant="contained"
                size="small"
                color={approved === false ? "error" : "button"}
              >
                <ThumbDown />
              </IconButton>
            ) : null}
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              textAlign: "center",
            }}
          >
            {props.allow ? (
              <IconButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                component="a"
                //href={props.img.data.imageURL}
                onClick={() => {
                  handleDownload(props.img.data.imageURL);
                }}
                variant="contained"
                size="small"
                color={"button"}
                className={classes.button}
              >
                <DownloadIcon />
              </IconButton>
            ) : null}

            <IconButton
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                props.setMeta(true);

                props.setImage({
                  image: props.img,
                  total: props.total,
                  idx: props.idx,
                });

                /*
                if (props.img.iptc) {
                  props.setIptc(props.img.iptc);
                } else {
                  props.setIptc("none");
                }

                var _temp = Object.assign({}, props.temp);
                _temp.current_image = props.img;
                props.setTemp(_temp);
 */
              }}
              variant="contained"
              size="small"
              color="button"
              className={classes.button}
            >
              <TagIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              textAlign: "right",
            }}
          >
            {!props.viewer ? (
              <IconButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={async () => {
                  var status = !approved ? true : "reset";

                  //setApproved(status === "reset" ? "" : status);

                  var _gallery = Object.assign({}, props.gallery);

                  _gallery.documents = _gallery.documents.map((g, i) => {
                    var _g = Object.assign({}, g);
                    if (g._id === props.img._id) {
                      _g.approved = status === "reset" ? "" : status;
                    }
                    return _g;
                  });

                  props.setGallery(_gallery);

                  await props.toggleApprove(
                    !approved ? true : "reset",
                    props.img._id
                  );
                }}
                variant="contained"
                size="small"
                color={approved === true ? "success" : "button"}
              >
                <ThumbUp />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  alert: state.alert,
  gallery: state.gallery,
  selected: state.selected,
  exif: state.exif,
  iptc: state.iptc,
  admin: state.admin,
  meta: state.meta,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setGallery: (gallery) => dispatch(setGallery(gallery)),
  setImage: (image) => dispatch(setImage(image)),
  setExif: (exif) => dispatch(setExif(exif)),
  setIptc: (iptc) => dispatch(setIptc(iptc)),
  setMeta: (meta) => dispatch(setMeta(meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageCard);
