import React from "react";
import { connect } from "react-redux";
import { setAlert, setTemp, setClients } from "../../actions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Fade from "@mui/material/Fade";
import ClientFolder from "./ClientFolder";
import Pages from "../../components/Pages";
import Loader from "../../components/Loader";
import { useParams } from 'react-router-dom';

const CancelToken = axios.CancelToken;

let source = CancelToken.source();

function Folders(props) {
  const theme = useTheme();

  const params = useParams();

  const [search_term, setSearchTerm] = React.useState("");
  const [folders, setFolders] = React.useState(null);

  
  const [client, setClient] = React.useState(params.client);

  const [notify, setNotify] = React.useState({ type: "off" });

  const [loading, setLoading] = React.useState(false);

  const [loaded, setLoaded] = React.useState(false);

  const [pagination, setPagination] = React.useState({
    page: 1,
    skip: 0,
    limit: 5,
    max: 5,
  });

  React.useEffect(() => {
    var load = async () => {
      setLoaded(true);

      axios
        .post("/remote/clients/search/", {
          query: {
            pagination: {
              page: 1,
              skip: 0,
              limit: 50,
              max: 5,
            },
          },
          page: 0,
        })
        .then((result) => {
          var newClients = result.data.data.map((c, i) => {
            return {
              name: c.name,
              _id: c._id,
              uuid: c.uuid,
            };
          });

          var newObj = {
            total: newClients.length,
            data: newClients,
          };

          props.setClients(newObj);
        })
        .catch((err) => {});
    };

    if (!loaded && !props.clients.data.length) {
      load();
    }
  }, [props, loaded]);

  React.useEffect(() => {
    const _getLabel = (folder) => {
      var client_label = "";

      var findClient = props.clients.data.findIndex(
        (x) => x._id === folder.client
      );

      if (findClient > -1) {
        var found = props.clients.data[findClient];

        client_label = found.name;
        var newClient = {
          label: client_label,
          _id: found._id,
          uuid: found.uuid
        };

        return newClient;
      }
    };

    const search = async (filter) => {
      axios
        .post("/remote/folders/search", {
          query: {
            search_term: "",
            page: 0,
            client: client ? filter._id : null,
            pagination: pagination,
          },
          deleted: true,
        })
        .then((results) => {
          var folder_map = results.data.data.map((f, i) => {
            var labeled = _getLabel(f);            


            f.client = labeled;


            
            return f;
          });

          results.data.data = folder_map;

          setFolders(results.data);
          if (filter && filter === "all") {
            setSearchTerm("");
          }
        })
        .catch((err) => {
          setNotify({ type: "error", message: "search error" });
        });
    };

    var load = async () => {
      if (client && client._id) {
        search({ _id: client });
      } else if (client === null) {
        setFolders(null);
      }
    };

    if (client || client === null) {
      load();
    }
  }, [client, props.clients, pagination]);

  React.useEffect(()=>{

    const _getLabel = (c,d) => {
        var client_label = "";

        var findClient = d.findIndex(
          (x) => x._id === c
        );

        if (findClient > -1) {
          var found = d[findClient];
  
          client_label = found.name;
          var newClient = {
            label: client_label,
            _id: found._id,
          };
  
          return newClient;
        }
      };
  

    if (client && !client._id && props.clients.data && props.clients.data.length) {
        
        var labeled = _getLabel(client,props.clients.data);
        labeled.uuid = client._id

        setClient(labeled)
        
    }
  },[client,props.clients])
  
  const handleClose = () => {
    setNotify({ type: "off" });
  };

  const getLabel = (folder) => {
    var client_label = "";

    var findClient = props.clients.data.findIndex(
      (x) => x._id === folder.client
    );

    if (findClient > -1) {
      var found = props.clients.data[findClient];

      client_label = found.name;
      var newClient = {
        label: client_label,
        _id: found._id,
        uuid: found.uuid,
      };

      return newClient;
    }
  };

  const paginationSearch = async (pagination_settings) => {
    searchFolders(null, pagination_settings);
  };

  const searchFolders = async (filter, pagination_settings) => {
    source.cancel("cancelled");
    source = CancelToken.source();

    if (!loading) {
      setLoading(true);
    }

    if (!pagination_settings) {
      pagination_settings = pagination;
    }

    axios
      .post(
        "/remote/folders/search",
        {
          query: {
            search_term: filter && filter === "all" ? "" : search_term,
            page: 0,
            client: client ? client : null,
            pagination: pagination_settings,
          },
          deleted: true,
        },
        { cancelToken: source.token }
      )
      .then((results) => {
        var folder_map = results.data.data.map((f, i) => {
          var labeled = getLabel(f);

          f.client = labeled;

          return f;
        });

        results.data.data = folder_map;

        var newFolders = {
          total: results.data.total,
          data: folder_map,
        };

        setFolders(newFolders);

        if (filter && filter === "all") {
          setSearchTerm("");
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err && err.message === "cancelled") {
          setNotify({ type: "warning", message: "previous search replaced" });
        } else {
          setLoading(false);
          setNotify({ type: "error", message: "search error" });
        }
      });
  };

  const deleteFolder = (_id) => {
    axios
      .post("/remote/folders/delete", {
        _id: _id,
      })
      .then((result) => {
        if (result.data.message === "deleted") {
          var newFolders = folders.data.slice();

          newFolders.forEach((v, i) => {
            if (v._id.toString() === _id.toString()) {
              newFolders.splice(i, 1);
            }
          });

          setFolders({ total: folders.total, data: newFolders });

          setNotify({ type: "success", message: "folder deleted" });
          searchFolders();
        }
      })
      .catch((err) => {});
  };

  const softDeleteFolder = (_id) => {
    axios
      .post("/remote/folders/soft", {
        _id: _id,
      })
      .then((result) => {
        if (result.data.message === "softdeleted") {
          var newFolders = folders.data.slice();

          var updateFolders = [];

          newFolders.forEach((v, i) => {
            var newFolder = v;
            if (v._id.toString() === _id.toString()) {
              newFolder.deleted = true;
            }
            updateFolders.push(newFolder);
          });

          setFolders({ total: folders.total, data: updateFolders });

          setNotify({ type: "success", message: "folder soft deleted" });
        }
      })
      .catch((err) => {});
  };



  const updateFolder = (_id, folder_name, client) => {
    axios
      .post("/remote/folders/update", {
        _id: _id,
        name: folder_name,
        client: client,
      })
      .then((result) => {
        if (result.data.message === "updated") {
          var currentFolders = folders.data.slice();

          var newFolders = [];

          currentFolders.forEach((v, i) => {
            if (v._id.toString() === _id.toString()) {
              v.name = folder_name;
              v.client = client;
              newFolders.push(v);
            } else {
              newFolders.push(v);
            }
          });

          setFolders({ total: folders.total, data: newFolders.slice() });

          setNotify({ type: "success", message: "folder updated" });
          //searchFolders();
        }
      })
      .catch((err) => {});
  };


  
  const sweep = (_id) => {
    axios
      .post("/remote/sweep", {
        _id: _id,        
      })
      .then((result) => {
        if (result.data.message === "done") {
          setNotify({ type: "success", message: "folder swept" });          
        }
      })
      .catch((err) => {});
  };

  const upload = (_id) => {
    axios
      .post("/remote/populate", {
        _id: _id,        
      })
      .then((result) => {
        if (result.data.message === "done") {
          setNotify({ type: "success", message: "files uploaded" });          
        }
      })
      .catch((err) => {});
  };


  if (folders && folders.data && folders.data.length) {
    var folderMap = folders.data.map((v, i) => {
      return (
        <Grid key={"folder+" + v._id} item>
          <ClientFolder
            upload={upload}
            sweep={sweep}
            getLabel={getLabel}
            folder={v}
            updateFolder={updateFolder}
            folders={folders}
            softDeleteFolder={softDeleteFolder}
            deleteFolder={deleteFolder}
          />
        </Grid>
      );
    });
  } else {
    folderMap = [];
  }

  var instructions = (
    <div>
      Search for folders by name or press "Show All" to retrieve a complete
      list.
      <br />
      <br />
    </div>
  );



  return (
    <React.Fragment>
      {/*
      <div style={{ marginTop: theme.spacing(1) }}>
        <Pages
          setPagination={setPagination}
          isSmall={true}
          callback={paginationSearch}
          pagination={pagination}
          total={folders && folders.total ? folders.total : 0}
        />
        <div style={{ height: "4px" }}>{loading ? <Loader /> : null}</div>
      </div>
      */}

      <Grid
        xs={12}
        container
        item
        spacing={0}
        flexDirection="column"
        style={{ marginTop: theme.spacing(2) }}
      >
        <Grid item xs={12}>
          Folders for {client.label}
        </Grid>
        <Grid
          xs={12}
          container
          item
          spacing={2}
          direction={"column"}
          style={{ marginTop: "0px" }}
        >
         

          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                  color="secondary"
                  variant="outlined"
                  value={search_term}
                  placeholder={"search for folders by name"}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    searchFolders();
                  }}
                  variant="contained"
                  size="medium"
                  color="info"
                >
                  Search Folders
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container spacing={2} item xs={12} flexDirection="row">
            <Grid item>
              <Button
                onClick={() => {
                  searchFolders("all");
                }}
                variant="contained"
                size="medium"
                color={"custom"}
              >
                Show All
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setSearchTerm("");
                  setFolders(null);
                }}
                variant="contained"
                size="medium"
                color="sub"
              >
                Clear Results
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {instructions}
            {!folders ? (
              "Search results will be displayed here."
            ) : folderMap.length ? (
              <Grid spacing={2} container flexDirection="column">
                {folderMap}
              </Grid>
            ) : (
              "No Matching Folders Found"
            )}
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(2) }}
      >
        <div style={{ height: "4px", marginBottom: "8px" }}>
          {loading ? <Loader /> : null}
        </div>
        <Pages
          setPagination={setPagination}
          align="left"
          callback={paginationSearch}
          pagination={pagination}
          total={folders && folders.total ? folders.total : 0}
        />
      </div>
      {notify.type !== "off" ? (
        <Snackbar
          open={notify !== "off"}
          autoHideDuration={2000}
          onClose={handleClose}
          TransitionComponent={Fade}
          message={notify.message}
          key={Fade.name}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={notify.type ? notify.type : "info"}
            sx={{ width: "100%" }}
          >
            {notify.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
  alert: state.alert,
  clients: state.clients,
});

const mapDispatchToProps = (dispatch) => ({
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setClients: (clients) => dispatch(setClients(clients)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Folders);
