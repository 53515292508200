import React from "react";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setGallery } from "../actions";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import axios from "axios";
import { Typography } from "@mui/material";

function Dashboard(props) {
  const [status, setStatus] = React.useState({ loaded: false });
  const [action, setAction] = React.useState("");
  const [errors, setErrors] = React.useState([]);
  const [updating, setUpdating] = React.useState(false);

  var retryFile = async (_id) => {
    setUpdating(_id);
    var _status = await axios
      .post(process.env.REACT_APP_UPLOAD_BACKEND + "/remote/files/retry", {
        _id: _id,
      })
      .catch((e) => {
        setUpdating(false);
      });

    setErrors(_status.data.errors.slice());
    setUpdating(false);
    //getStatus();
  };

  var deleteFile = async (_id) => {
    setUpdating(_id);
    var _status = await axios
      .post(process.env.REACT_APP_UPLOAD_BACKEND + "/remote/files/deletefile", {
        _id: _id,
      })
      .catch((e) => {
        setUpdating(false);
      });

    setErrors(_status.data.errors.slice());
    setUpdating(false);
    //getStatus();
  };

  var getStatus = async () => {
    var _status = await axios.post(
      process.env.REACT_APP_UPLOAD_BACKEND + "/remote/users/status",
      {}
    );

    _status.data.loaded = true;
    setStatus(_status.data);
    setErrors(_status.data.errors.slice());
    setAction("");
  };

  React.useEffect(() => {
    getStatus();
  }, []);

  var refresh = async () => {
    setAction("refreshing");
    getStatus();
  };

  var restart = async () => {
    setAction("cancelling");
    await axios.post(
      process.env.REACT_APP_UPLOAD_BACKEND + "/remote/files/restart",
      {}
    );
    getStatus();
  };
  /*
  var force = async () => {
    setAction("restarting");
    await axios.post(
      process.env.REACT_APP_UPLOAD_BACKEND + "/remote/files/force",
      {}
    );
    getStatus();
  };
*/

  var clean = async () => {
    setAction("force restarting");
    await axios.post(
      process.env.REACT_APP_UPLOAD_BACKEND + "/remote/files/clean",
      {}
    );
    getStatus();
  };

  return (
    <Grid
      xs={12}
      container
      item
      spacing={0}
      flexDirection="column"
      style={{ marginTop: 20 }}
    >
      {status.loaded ? (
        <React.Fragment>
          <Grid item xs={12}>
            status:{" "}
            {action ? (
              action
            ) : (
              <React.Fragment>
                {" "}
                <strong>
                  {status.sweeping_message}{" "}
                  {status.force
                    ? "(force restarting)"
                    : status.abort
                    ? "(restarting)"
                    : null}
                </strong>{" "}
                {status.sweeping ? (
                  <React.Fragment>
                    (started:{" "}
                    <strong>{(status.sweeptime / 1000).toFixed(0)}</strong>{" "}
                    seconds ago)
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              size="medium"
              color="info"
              onClick={refresh}
              sx={{
                width: "200px",
              }}
            >
              Refresh
            </Button>
          </Grid>
          {status.sweeping || !status.sweeping ? (
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                size="medium"
                color="warning"
                onClick={restart}
                sx={{
                  width: "200px",
                }}
              >
                Cancel
              </Button>
            </Grid>
          ) : null}
          {status.sweeping || !status.sweeping ? (
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                size="medium"
                color="error"
                onClick={clean}
                sx={{
                  width: "200px",
                }}
              >
                Restart
              </Button>
            </Grid>
          ) : null}
          {1 === 2 && (status.sweeping || !status.sweeping) ? (
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                size="medium"
                color="error"
                onClick={clean}
                sx={{
                  width: "200px",
                }}
              >
                Forced
              </Button>
            </Grid>
          ) : null}
          {1 === 2 && action.length ? (
            <Grid item xs={12} sx={{ mt: 2 }}>
              {action}...
            </Grid>
          ) : null}

          {errors.length ? (
            <>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h5">Errors</Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }}>
                {errors.map((e, i) => {
                  return (
                    <Grid
                      item
                      container
                      xs={12}
                      key={i}
                      sx={{
                        backgroundColor:
                          props.theme === "dark" ? "#2a2a2a" : "#c0c0c0",
                        mt: 1,
                        p: 1,
                        border: "1px solid #fefefe",
                      }}
                    >
                      <Grid item xs={12} sm={6}>
                        <Grid item xs={12}>
                          <div>file: {e.name}</div>

                          <div>
                            folder: {e.pin} - {e.folder.name}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          {e.resized?.length ? (
                            <div>resized: {e.resized}</div>
                          ) : null}

                          {e.uploaded?.length ? (
                            <div>uploaded: {e.uploaded}</div>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          display: "flex",
                          whiteSpace: "space-around",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="info"
                          onClick={(event) => {
                            retryFile(e._id);
                          }}
                          disabled={updating === e._id ? true : false}
                        >
                          Retry
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ ml: 1 }}
                          onClick={(event) => {
                            deleteFile(e._id);
                          }}
                          disabled={updating === e._id ? true : false}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          ) : null}
        </React.Fragment>
      ) : (
        <Grid item xs={12}>
          Loading...
        </Grid>
      )}
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  alert: state.alert,
  gallery: state.gallery,
  theme: state.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setGallery: (gallery) => dispatch(setGallery(gallery)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
