import React from "react";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp } from "../actions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Fade from "@mui/material/Fade";
import User from "./User";

function Users(props) {
  const theme = useTheme();

  const [search_term, setSearchTerm] = React.useState("");
  const [users, setUsers] = React.useState(null);

  const [userName, setUserName] = React.useState("");
  const [displayName, setDisplayName] = React.useState("");
  const [notify, setNotify] = React.useState({ type: "off" });

  const handleClose = () => {
    setNotify({ type: "off" });
  };

  const searchUsers = (filter) => {
    axios
      .post("/remote/users/search", {
        query: {
          search_term: filter && filter === "all" ? "" : search_term,
          page: 0,
        },
        deleted: true,
      })
      .then((results) => {
        setUsers(results.data);
        if (filter && filter === "all") {
          setSearchTerm("");
        }
      })
      .catch((err) => {
        setNotify({ type: "error", message: "search error" });
      });
  };

  const addUser = () => {
    axios
      .post("/remote/users/add", {
        name: userName,
        displayName: displayName,
      })
      .then((result) => {
        if (result.data.message === "added") {
          setNotify({ type: "success", message: "user added" });

          var newUsers = users && users.data ? users.data.slice() : [];

          var total = users && users.total ? users.total : 0;

          var newUser = {
            _id: result.data._id,
            name: userName,
            displayName: displayName,
          };

          newUsers.unshift(newUser);

          setUsers({ total: total, data: newUsers.slice() });
          setUserName("");
          setDisplayName("");
        }
      })
      .catch((err) => {});
  };

  const deleteUser = (_id) => {
    axios
      .post("/remote/users/delete", {
        _id: _id,
      })
      .then((result) => {
        if (result.data.message === "deleted") {
          var newUsers = users.data.slice();

          newUsers.forEach((v, i) => {
            if (v._id.toString() === _id.toString()) {
              newUsers.splice(i, 1);
            }
          });

          setUsers({ total: users.total, data: newUsers });

          setNotify({ type: "success", message: "user deleted" });
        }
      })
      .catch((err) => {});
  };

  const softDeleteUser = (_id) => {
    axios
      .post("/remote/users/soft", {
        _id: _id,
      })
      .then((result) => {
        if (result.data.message === "softdeleted") {
          var newUsers = users.data.slice();

          var updateUsers = [];

          newUsers.forEach((v, i) => {
            var newUser = v;
            if (v._id.toString() === _id.toString()) {
              newUser.deleted = true;
            }
            updateUsers.push(newUser);
          });

          setUsers({ total: users.total, data: updateUsers });

          setNotify({ type: "success", message: "user soft deleted" });
        }
      })
      .catch((err) => {});
  };

  const updateUser = (_id, user_name, display_name) => {
    axios
      .post("/remote/users/update", {
        _id: _id,
        name: user_name,
        displayName: display_name,
      })
      .then((result) => {
        if (result.data.message === "updated") {
          var currentUsers = users.data.slice();

          var newUsers = [];

          currentUsers.forEach((v, i) => {
            if (v._id.toString() === _id.toString()) {
              v.name = user_name;
              v.displayName = display_name;
              newUsers.push(v);
            } else {
              newUsers.push(v);
            }
          });

          setUsers({ total: users.total, data: newUsers.slice() });

          setNotify({ type: "success", message: "user updated" });
        }
      })
      .catch((err) => {});
  };

  if (users && users.data && users.data.length) {
    var userMap = users.data.map((v, i) => {
      return (
        <Grid key={"user+" + i} item>
          <User
            user={v}
            updateUser={updateUser}
            users={users}
            softDeleteUser={softDeleteUser}
            deleteUser={deleteUser}
          />
        </Grid>
      );
    });
  } else {
    userMap = [];
  }

  var instructions = (
    <div>
      1. Add users to the database with the "Add User" form.
      <br />
      2. Search for users by name or press "Show All" to retrieve a complete
      list.
      <br />
      <br />
    </div>
  );

  return (
    <React.Fragment>
      <Grid
        xs={12}
        container
        item
        spacing={0}
        flexDirection="column"
        style={{ marginTop: theme.spacing(2) }}
      >
        <Grid item xs={12}>
          Current Page: Users
        </Grid>
        <Grid
          xs={12}
          container
          item
          spacing={2}
          direction={"column"}
          style={{ marginTop: "0px" }}
        >
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                  sx={{
                    input: {
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                    "& fieldset": {
                      borderColor: theme.palette.secondary.dark,
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: theme.palette.secondary.main,
                      },
                    },
                    label: { color: theme.palette.secondary.main },
                  }}
                  color="secondary"
                  variant="outlined"
                  value={userName}
                  label={"username"}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
              <Grid item>
                <TextField
                  sx={{
                    input: {
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                    "& fieldset": {
                      borderColor: theme.palette.secondary.dark,
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: theme.palette.secondary.main,
                      },
                    },
                    label: { color: theme.palette.secondary.main },
                  }}
                  color="secondary"
                  variant="outlined"
                  value={displayName}
                  label={"display name"}
                  onChange={(e) => {
                    setDisplayName(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    addUser();
                  }}
                  variant="contained"
                  size="medium"
                  color="success"
                >
                  Add User
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                  sx={{
                    input: {
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                    "& fieldset": {
                      borderColor: theme.palette.secondary.dark,
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: theme.palette.secondary.main,
                      },
                    },
                    label: { color: theme.palette.secondary.main },
                  }}
                  color="secondary"
                  variant="outlined"
                  value={search_term}
                  label={"search for users by name"}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    searchUsers();
                  }}
                  variant="contained"
                  size="medium"
                  color="info"
                >
                  Search Users
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container spacing={2} item xs={12} flexDirection="row">
            <Grid item>
              <Button
                onClick={() => {
                  searchUsers("all");
                }}
                variant="contained"
                size="medium"
                color={"custom"}
              >
                Show All
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setSearchTerm("");
                  setUsers(null);
                }}
                variant="contained"
                size="medium"
                color="sub"
              >
                Clear Results
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {instructions}
            {!users ? (
              "Search results will be displayed here."
            ) : userMap.length ? (
              <Grid spacing={2} container flexDirection="column">
                {userMap}
              </Grid>
            ) : (
              "No Matching Users Found"
            )}
          </Grid>
        </Grid>
      </Grid>
      {notify.type !== "off" ? (
        <Snackbar
          open={notify !== "off"}
          autoHideDuration={2000}
          onClose={handleClose}
          TransitionComponent={Fade}
          message={notify.message}
          key={Fade.name}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={notify.type ? notify.type : "info"}
            sx={{ width: "100%" }}
          >
            {notify.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
