import React from "react";
import { connect } from "react-redux";
import { setAlert, setTemp, setClients } from "../../actions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Fade from "@mui/material/Fade";
import Client from "./Client";
import Pages from "../../components/Pages";
import Loader from "../../components/Loader";
import UpArrow from "@mui/icons-material/ArrowUpward";
import DownArrow from "@mui/icons-material/ArrowDownward";

const CancelToken = axios.CancelToken;
const cCancelToken = axios.CancelToken;

let source = CancelToken.source();
let csource = cCancelToken.source();

function Clients(props) {
  const theme = useTheme();

  const [search_term, setSearchTerm] = React.useState("");

  const [clientName, setClientName] = React.useState("");
  const [displayName, setDisplayName] = React.useState("");
  const [notify, setNotify] = React.useState({ type: "off" });

  const [sort, setSort] = React.useState("asc");

  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    skip: 0,
    limit: 20,
    max: 20,
  });

  const handleClose = () => {
    setNotify({ type: "off" });
  };

  const paginationSearch = async (pagination_settings) => {
    searchClients(null, pagination_settings);
  };

  const searchClients = (filter, pagination_settings, order) => {
    source.cancel("cancelled");
    source = CancelToken.source();

    if (!loading) {
      setLoading(true);
    }

    if (!pagination_settings) {
      pagination_settings = pagination;
    }

    axios
      .post("/remote/clients/search", {
        query: {
          search_term: filter && filter === "all" ? "" : search_term,
          page: 0,
          pagination: pagination_settings,
          sort: order,
        },
        deleted: true,
      })
      .then((results) => {
        props.setClients(results.data);

        if (filter && filter === "all") {
          setSearchTerm("");
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err && err.message === "cancelled") {
          setNotify({ type: "warning", message: "previous search replaced" });
        } else {
          setLoading(false);
          setNotify({ type: "error", message: "search error" });
        }
      });
  };

  const addClient = () => {
    axios
      .post("/remote/clients/add", {
        name: clientName,
        displayName: displayName,
      })
      .then((result) => {
        if (result.data.message === "added") {
          setNotify({ type: "success", message: "client added" });

          //newClients.unshift(newClient);

          //props.setClients({ total: newClients.length, data: newClients.slice() });
          setClientName("");
          setDisplayName("");
          searchClients();
        }
      })
      .catch((err) => {});
  };

  React.useEffect(() => {
    const loadClients = (filter, pagination_settings, order) => {
      csource.cancel("cancelled");
      csource = CancelToken.source();

      if (!loading) {
        setLoading(true);
      }

      if (!pagination_settings) {
        pagination_settings = pagination;
      }

      axios
        .post("/remote/clients/search", {
          query: {
            search_term: filter && filter === "all" ? "" : search_term,
            page: 0,
            pagination: pagination_settings,
            sort: order,
          },
          deleted: true,
        })
        .then((results) => {
          props.setClients(results.data);

          if (filter && filter === "all") {
            setSearchTerm("");
          }
          setLoading(false);
        })
        .catch((err) => {
          if (err && err.message === "cancelled") {
            setNotify({ type: "warning", message: "previous search replaced" });
          } else {
            setLoading(false);
            setNotify({ type: "error", message: "search error" });
          }
        });
    };
    if (!loaded) {
      setLoaded(true);
      loadClients();
    }
  }, [loading, pagination, props, search_term, loaded]);

  const deleteClient = (_id) => {
    axios
      .post("/remote/clients/delete", {
        _id: _id,
      })
      .then((result) => {
        if (result.data.message === "deleted") {
          var newClients = props.clients.data.slice();

          newClients.forEach((v, i) => {
            if (v._id.toString() === _id.toString()) {
              newClients.splice(i, 1);
            }
          });

          props.setClients({
            total: props.clients.data.length - 1,
            data: newClients,
          });

          setNotify({ type: "success", message: "client deleted" });

          searchClients();
        }
      })
      .catch((err) => {});
  };

  const softDeleteClient = (_id) => {
    axios
      .post("/remote/clients/soft", {
        _id: _id,
      })
      .then((result) => {
        if (result.data.message === "softdeleted") {
          var newClients = props.clients.data.slice();

          var updateClients = [];

          newClients.forEach((v, i) => {
            var newClient = v;
            if (v._id.toString() === _id.toString()) {
              newClient.deleted = true;
            }
            updateClients.push(newClient);
          });

          props.setClients({ total: props.clients.total, data: updateClients });

          setNotify({ type: "success", message: "client soft deleted" });
        }
      })
      .catch((err) => {});
  };

  const updateClient = (_id, client_name, display_name) => {
    axios
      .post("/remote/clients/update", {
        _id: _id,
        name: client_name,
        displayName: display_name,
      })
      .then((result) => {
        if (result.data.message === "updated") {
          var currentClients = props.clients.data.slice();

          var newClients = [];

          currentClients.forEach((v, i) => {
            if (v._id.toString() === _id.toString()) {
              v.name = client_name;
              v.displayName = display_name;
              newClients.push(v);
            } else {
              newClients.push(v);
            }
          });

          props.setClients({
            total: props.clients.total,
            data: newClients.slice(),
          });

          setNotify({ type: "success", message: "client updated" });
        }
      })
      .catch((err) => {});
  };

  if (props.clients && props.clients.data && props.clients.data.length) {
    var clientMap = props.clients.data.map((v, i) => {
      return (
        <Grid key={"client+" + v._id} item>
          <Client
            client={v}
            updateClient={updateClient}
            clients={props.clients}
            softDeleteClient={softDeleteClient}
            deleteClient={deleteClient}
          />
        </Grid>
      );
    });
  } else {
    clientMap = [];
  }

  var instructions = (
    <div>
      1. Add clients to the database with the "Add Client" form.
      <br />
      2. Search for clients by name or press "Show All" to retrieve a complete
      list.
      <br />
      <br />
    </div>
  );

  return (
    <React.Fragment>
      <Grid
        xs={12}
        container
        item
        spacing={0}
        flexDirection="column"
        style={{ marginTop: theme.spacing(2) }}
      >
        <Grid item xs={12}>
          Current Page: Clients
        </Grid>
        <Grid
          xs={12}
          container
          item
          spacing={2}
          direction={"column"}
          style={{ marginTop: "0px" }}
        >
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                  color="secondary"
                  variant="outlined"
                  value={clientName}
                  placeholder={"client name"}
                  onChange={(e) => {
                    setClientName(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
              {/*
              <Grid item>
                <TextField
                  color="secondary"
                  variant="outlined"
                  value={displayName}
                  placeholder={"display name"}
                  onChange={(e) => {
                    setDisplayName(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
                */}
              <Grid item>
                <Button
                  onClick={() => {
                    addClient();
                  }}
                  variant="contained"
                  size="medium"
                  color="success"
                >
                  Add Client
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                  color="secondary"
                  variant="outlined"
                  value={search_term}
                  placeholder={"search for clients by name"}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    searchClients();
                  }}
                  variant="contained"
                  size="medium"
                  color="info"
                >
                  Search Clients
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container spacing={2} item xs={12} flexDirection="row">
            <Grid item>
              <Button
                onClick={() => {
                  searchClients("all");
                }}
                variant="contained"
                size="medium"
                color={"custom"}
              >
                Show All
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setSearchTerm("");
                  props.setClients({ total: 0, data: [] });
                }}
                variant="contained"
                size="medium"
                color="sub"
              >
                Clear Results
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {instructions}
            {!props.clients ? (
              "Search results will be displayed here."
            ) : clientMap.length ? (
              <Grid spacing={2} container flexDirection="column">
                {props.clients && props.clients.total ? (
                  <Grid item>
                    <Button
                      onClick={() => {
                        if (sort === "asc") {
                          setSort("desc");
                          searchClients(null, null, "desc");
                        } else {
                          setSort("asc");
                          searchClients(null, null, "asc");
                        }
                      }}
                      startIcon={sort === "asc" ? <UpArrow /> : <DownArrow />}
                    >
                      Sort
                    </Button>
                  </Grid>
                ) : null}

                {clientMap}
              </Grid>
            ) : (
              "No Matching Clients Found"
            )}
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(2) }}
      >
        <div style={{ height: "4px", marginBottom: "8px" }}>
          {loading ? <Loader /> : null}
        </div>

        <Pages
          setPagination={setPagination}
          align="left"
          callback={paginationSearch}
          pagination={pagination}
          total={props.clients && props.clients.total ? props.clients.total : 0}
        />
      </div>
      {notify.type !== "off" ? (
        <Snackbar
          open={notify !== "off"}
          autoHideDuration={2000}
          onClose={handleClose}
          TransitionComponent={Fade}
          message={notify.message}
          key={Fade.name}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={notify.type ? notify.type : "info"}
            sx={{ width: "100%" }}
          >
            {notify.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
  alert: state.alert,
  clients: state.clients,
});

const mapDispatchToProps = (dispatch) => ({
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setClients: (clients) => dispatch(setClients(clients)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
