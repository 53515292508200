import React from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

function Reset(props) {
  return (
    <React.Fragment>
      <Container maxWidth="sm" style={{marginTop: '24px'}}>
          <Button variant="contained" fullWidth href="/deletefiles" target="_new">Delete Everything from database</Button>
          <br /><br />
          <Button variant="contained" fullWidth href="/deletefiles" target="_new">Delete Everything from S3</Button>
          <br /><br />
          <Button variant="contained" fullWidth href="/scanfiles?dir=home" target="_new">Populate Home Page</Button>
      </Container>
    </React.Fragment>
  );
}

export default Reset;