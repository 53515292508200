import "./App.css";
import React from "react";
import Routes from "./Routes";
import { ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import { setTheme, setTemp, setAlert } from "./actions";
import CssBaseline from "@mui/material/CssBaseline";
import Confirm from "./Confirm";
import lightTheme from "./themes/light";
import darkTheme from "./themes/dark";
import AppBar from "./components/AppBar";
import Drawer from "./components/Drawer";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { useTheme, useMediaQuery } from "@mui/material";
import Exif from "./views/Exif";
import IPTC from "./views/IPTC";

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

const Offset = styled("div")(({ theme, height }) => {
  return { ...theme.mixins.toolbar, height: height };
});

function App(props) {
  const script_host = React.useRef(null);

  const theme = useTheme();

  var matches = theme.breakpoints.only("sm");
  var isSmall = useMediaQuery(matches);

  matches = theme.breakpoints.only("xs");
  var isXSmall = useMediaQuery(matches);

  return (
    <React.Fragment>
      <ThemeProvider theme={themes[props.theme]}>
        <CssBaseline />
        <AppBar />
        <Offset height={isXSmall ? "56px" : isSmall ? "64px" : "70px"} />
        <Drawer />
        <Exif />
        <IPTC />
        <Container maxWidth="xl">
          <Routes />
        </Container>
        <Confirm />
      </ThemeProvider>
      <div style={{ display: "none" }} ref={script_host}></div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  theme: state.theme,
  temp: state.temp,
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  setTheme: (theme) => dispatch(setTheme(theme)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setAlert: (alert) => dispatch(setAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
