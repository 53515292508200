import React from "react";
import Button from "@mui/material/Button";
//import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { setTemp } from "../actions";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import { styled } from "@mui/material/styles";

const _details = {
  host: "",
  username: "",
  password: "",
  port: "",
  path: "/",
  mode: "delete",
  secure: "normal",
  delete: true,
};

function Confirm(props) {
  const [details, setDetails] = React.useState(_details);

  const handleClose = () => {
    props.setDownloading(false);
  };

  const handleChange = (e) => {
    var _new = Object.assign({}, details);
    _new.delete = !_new.delete;
    setDetails(_new);
    //localStorage.setItem("details", JSON.stringify(_new));
  };

  if (!props.downloading) {
    return null;
  }

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiSwitch-track": {
      backgroundColor: "#39393D",
      opacity: 1,
    },
  }));

  return (
    <Dialog
      open={props.downloading}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
      scroll="paper"
    >
      <DialogTitle style={{ paddingBottom: "8px" }}>
        Gallery Downloader
      </DialogTitle>
      <DialogContent style={{ paddingTop: "0px" }}>
        {props.lastFile ? (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                component="a"
                href={props.lastFile.link}
                download={"Download .zip file"}
                variant="contained"
                size="small"
                color="success"
                sx={{ mt: 1 }}
                //downloadTitle={props.lastFile.title}
                fullWidth
              >
                Download existing .zip file
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {props.lastFile ? (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <LoadingButton
                onClick={async () => {
                  if (window.confirm("Are you sure?")) {
                    await axios
                      .post("/remote/files/clearlast", {
                        folder: props.folderInfo._id,
                      })
                      .catch(() => {});

                    props.setLastFile(null);
                  }
                }}
                variant="contained"
                size="small"
                color="error"
                loading={props.zipping}
                loadingPosition="start"
                startIcon={<DownloadIcon />}
                sx={{ mt: 1, mr: 1 }}
                fullWidth
              >
                Delete Existing .zip
              </LoadingButton>
            </Grid>
          </Grid>
        ) : null}

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <LoadingButton
              onClick={async () => {
                await axios
                  .post("/remote/files/clearlast", {
                    folder: props.folderInfo._id,
                  })
                  .catch(() => {});

                props.setLastFile(null);
                if (props.selected.length) {
                  props.downloadSelected();
                } else {
                  props.downloadAll();
                }
              }}
              variant="contained"
              size="small"
              loading={props.zipping}
              loadingPosition="start"
              startIcon={<DownloadIcon />}
              sx={{ mt: 1, mr: 1 }}
              fullWidth
            >
              {props.zipping
                ? "Preparing"
                : props.selected.length
                ? "New Download (" + props.selected.length + ")"
                : "New Download (All)"}
            </LoadingButton>
          </Grid>
        </Grid>

        {props.lastFile && 1 === 2 ? (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={details.delete}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Edit" }}
                      color="success"
                    />
                  }
                  label="Delete after download"
                />
              </FormGroup>
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>

        {/*
        <LoadingButton
          onClick={() => {
            props.callback(details);
          }}
          variant="contained"
          size="small"
          loading={props.uploading}
          loadingPosition="start"
          startIcon={<FTPIcon />}
          sx={{ ml: 1, mt: 1 }}
        >
          Save & Upload
        </LoadingButton>
        */}
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
  selected: state.selected,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
