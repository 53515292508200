import { createTheme } from "@mui/material/styles";
import secondaryColor from "@mui/material/colors/grey";
import dangerColor from "@mui/material/colors/orange";
import errorColor from "@mui/material/colors/red";
import { darken } from "@mui/material/styles";

var shadows = [];
for (var i = 0; i < 25; i++) {
  shadows[i] = "none";
}

const darkTheme = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: secondaryColor[500],
            color: "#ffffff",
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: secondaryColor[900] + " !important",
        },
        bar: {
          backgroundColor: darken(secondaryColor[900], 0.5) + " !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#000000",
          color: "#ffffff",
        },
        input: {
          "&::placeholder": {
            opacity: 0.75,
            textOverflow: "ellipsis !important",
            color: secondaryColor[700],
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#404040",
          boxShadow:
            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
          borderRadius: "0px !important",
          color: secondaryColor[500],
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          "&::-webkit-scrollbar": {},
          "&::-webkit-scrollbar-thumb": {},
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0px !important",
        },
        contained: {
          color: "#ffffff",
        },
        containedPrimary: {
          color: "#ffffff",
        },
        containedSecondary: {
          color: "#ffffff",
        },
      },
    },
    MuiAutocomplete: {
      option: {
        '&[data-focus="true"]': {
          backgroundColor: "#ff0000 !important",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          colorScheme: "dark",
        },
        "*": {
          "&::-webkit-scrollbar": {},
          "&::-webkit-scrollbar-track": {},
          "&::-webkit-scrollbar-thumb": {},
          "&::-webkit-scrollbar-thumb:hover": {},
        },
        ".Mui-disabled": {
          backgroundColor: "#0000002f!important",
          color: "#f0f0f0 !important",
        },
        ".MuiAutocomplete-inputRoot": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: secondaryColor[500] + " !important",
          },
        },
        ".MuiAutocomplete-root": {
          ".MuiInputLabel-shrink": {
            color: secondaryColor[500] + " !important",
          },
          ".MuiSvgIcon-root": {
            fill: secondaryColor[500] + " !important",
          },
        },
        ".MuiAutocomplete-option.Mui-focused": {
          backgroundColor: secondaryColor[800] + " !important",
        },

        "& .MuiOutlinedInput-root": {
          "& > fieldset": {
            borderColor: secondaryColor[500],
          },
        },

        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            borderColor: secondaryColor[500] + " !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ffffff !important",
          },
        },

        ".Mui-focused": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#ffffff !important",
          },
        },
      },
    },
  },
  shadows: shadows,
  palette: {
    type: "dark",
    mode: "light",
    error: {
      light: errorColor[300],
      main: errorColor[400],
      dark: errorColor[700],
      contrastText: darken(errorColor[500], 0.3),
    },
    danger: {
      light: dangerColor[300],
      main: dangerColor[400],
      dark: dangerColor[700],
      contrastText: darken(dangerColor[500], 0.3),
    },
    primary: {
      main: "#000000",
    },
    custom: {
      light: secondaryColor[300],
      main: "#000000",
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    dropdown: {
      light: secondaryColor[300],
      main: "#000000",
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    sub: {
      light: secondaryColor[300],
      main: secondaryColor[500],
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    secondary: {
      light: secondaryColor[300],
      main: secondaryColor[500],
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    background: {
      default: secondaryColor[900],
    },
    text: {
      primary: secondaryColor[500],
    },
    bright: {
      light: secondaryColor[300],
      main: "#ffffff",
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    button: {
      light: secondaryColor[300],
      main: darken(secondaryColor[500], 0.5),
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    cards: {
      light: secondaryColor[300],
      main: darken(secondaryColor[700], 0.5),
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    highlight: {
      light: secondaryColor[300],
      main: "#ffffff",
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
  },
});

export default darkTheme;
