import React from "react";
import { connect } from "react-redux";
import { setAlert, setTemp, setClients, setClient } from "../../actions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
//import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Fade from "@mui/material/Fade";
import Folder from "./Folder";
import SelectClient from "../clients/Select";
import Pages from "../../components/Pages";
import Loader from "../../components/Loader";
import UpArrow from "@mui/icons-material/ArrowUpward";
import DownArrow from "@mui/icons-material/ArrowDownward";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";

const CancelToken = axios.CancelToken;

let source = CancelToken.source();

function Folders(props) {
  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiSwitch-track": {
      backgroundColor: "#39393D",
      opacity: 1,
    },
  }));

  const theme = useTheme();

  const [search_term, setSearchTerm] = React.useState("");
  const [folders, setFolders] = React.useState(null);
  const [sort, setSort] = React.useState("asc");
  const [archived, setArchived] = React.useState(false);
  const [folderName, setFolderName] = React.useState("");
  const [selectedClient, setselectedClient] = React.useState("");
  const [notify, setNotify] = React.useState({ type: "off" });

  const [loading, setLoading] = React.useState(false);

  const [loaded, setLoaded] = React.useState(false);
  const [loadedFolders, setLoadedFolders] = React.useState(false);
  //const [clients, setClients] = React.useState([]);
  const [folderValue, setFolderValue] = React.useState("");

  //const [searching, setSearching] = React.useState(false);

  const [pagination, setPagination] = React.useState({
    page: 1,
    skip: 0,
    limit: 20,
    max: 20,
  });

  var setClients = props.setClients;

  React.useEffect(() => {
    var newObj = {
      total: 0,
      data: [],
    };

    setClients(newObj);
  }, [setClients]);

  var sc = props.setClients;

  var st = props.setTemp;

  var setc = props.setClient;

  React.useEffect(() => {
    if (props.temp.client && props.temp.client._id !== selectedClient?._id) {
      var c = Object.assign({}, props.temp.client);

      setc(c);
    }
  }, [props.temp, setc, selectedClient, st]);

  React.useEffect(() => {
    var load = async () => {
      setLoaded(true);

      axios
        .post("/remote/clients/search", {
          query: {
            pagination: {
              page: 1,
              skip: 0,
              limit: 100,
              max: 20,
            },
          },
          page: 0,
        })
        .then((result) => {
          var newClients = result.data.data.map((c, i) => {
            return {
              name: c.name,
              _id: c._id,
              uuid: c.uuid,
            };
          });

          var newObj = {
            total: newClients.length,
            data: newClients,
          };

          sc(newObj);
        })
        .catch((err) => {});
    };

    if (!loaded) {
      load();
    }
  }, [sc, loaded]);

  var loadClients = async (_id, force) => {
    axios
      .post("/remote/clients/search", {
        query: {
          pagination: {
            page: 1,
            skip: 0,
            limit: 100,
            max: 20,
          },
        },
        page: 0,
      })
      .then((result) => {
        var m = null;

        var newClients = result.data.data.map((c, i) => {
          if (c._id === _id) {
            m = c;
          }

          return {
            name: c.name,
            _id: c._id,
            uuid: c.uuid,
          };
        });

        var newObj = {
          total: newClients.length,
          data: newClients,
        };

        props.setClients(newObj);

        var _temp = Object.assign({}, props.temp);
        _temp.inputValue = m.name;
        props.setTemp(_temp);

        props.setClient(m);

        if (force) {
          addFolder(m);
        }
      })
      .catch((err) => {});
  };

  React.useEffect(() => {
    const _getLabel = (folder) => {
      var client_label = "";

      var findClient = props.clients.data.findIndex(
        (x) => x._id === folder.client
      );

      if (findClient > -1) {
        var found = props.clients.data[findClient];

        client_label = found.name;
        var newClient = {
          label: client_label,
          _id: found._id,
          uuid: found.uuid,
        };

        return newClient;
      }
    };

    const search = async (filter) => {
      if (!window.loading) {
        window.loading = true;
      } else {
        return false;
      }

      axios
        .post("/remote/folders/search", {
          query: {
            search_term: "",
            page: 0,
            client: selectedClient
              ? filter && filter._id
                ? filter._id
                : selectedClient
              : null,
            pagination: pagination,
          },
          deleted: true,
          archived: archived,
        })
        .then((results) => {
          var folder_map = results.data.data.map((f, i) => {
            var labeled = _getLabel(f);

            f.client = labeled;

            return f;
          });

          results.data.data = folder_map;

          setFolders(results.data);
          if (filter && filter === "all") {
            setSearchTerm("");
          }
          delete window.loading;
        })
        .catch((err) => {
          setNotify({ type: "error", message: "search error" });
          delete window.loading;
        });
    };

    var load = async () => {
      if (selectedClient) {
        search({ _id: selectedClient });
      } else if (selectedClient === null) {
        setFolders(null);
      }
    };

    if (selectedClient || selectedClient === null) {
      if (props.clients.data.length) {
        load();
      }
    }

    if (!loadedFolders && loaded && props.clients.data.length) {
      setLoadedFolders(true);
      search();
    }
  }, [
    selectedClient,
    props.clients,
    pagination,
    loadedFolders,
    loaded,
    archived,
  ]);

  React.useEffect(() => {
    if (props.client && props.client._id !== selectedClient?._id) {
      setselectedClient(props.client);
    }
  }, [props.client, selectedClient]);

  const handleClose = () => {
    setNotify({ type: "off" });
  };

  const getLabel = (folder) => {
    var client_label = "";

    var findClient = props.clients.data.findIndex(
      (x) => x._id === folder.client
    );

    if (findClient > -1) {
      var found = props.clients.data[findClient];

      client_label = found.name;
      var newClient = {
        label: client_label,
        _id: found._id,
        uuid: found.uuid,
      };

      return newClient;
    }
  };

  const paginationSearch = async (pagination_settings) => {
    searchFolders(null, pagination_settings);
  };

  const searchFolders = async (
    filter,
    pagination_settings,
    order,
    reset,
    archive
  ) => {
    source.cancel("cancelled");
    source = CancelToken.source();

    if (!loading) {
      setLoading(true);
    }

    if (!pagination_settings) {
      pagination_settings = pagination;
    }

    axios
      .post(
        "/remote/folders/search",
        {
          query: {
            search_term: filter && filter === "all" ? "" : search_term,
            page: 0,
            client: selectedClient && !reset ? selectedClient : null,
            pagination: pagination_settings,
            sort: order,
          },
          deleted: true,
          archived: archive,
        },
        { cancelToken: source.token }
      )
      .then((results) => {
        var folder_map = results.data.data.map((f, i) => {
          var labeled = getLabel(f);

          f.client = labeled;

          return f;
        });

        results.data.data = folder_map;

        var newFolders = {
          total: results.data.total,
          data: folder_map,
        };

        setFolders(newFolders);

        if (filter && filter === "all") {
          setSearchTerm("");
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err && err.message === "cancelled") {
          setNotify({ type: "warning", message: "previous search replaced" });
        } else {
          setLoading(false);
          setNotify({ type: "error", message: "search error" });
        }
      });
  };

  const addFolder = (c) => {
    axios
      .post("https://upload.lestudio.photos/remote/folders/add", {
        name: folderName,
        client: c ? c : selectedClient ? selectedClient : null,
      })
      .then((result) => {
        if (result.data.message === "added") {
          setNotify({ type: "success", message: "folder added" });

          //newFolders.unshift(newFolder);

          //setFolders({ total: total, data: newFolders.slice() });
          setFolderName("");
          if (!c) {
            searchFolders();
          }
        } else if (result.data.message === "exists") {
          setNotify({ type: "warning", message: "folder exists" });
        }
      })
      .catch((err) => {});
  };

  const deleteFolder = (_id) => {
    axios
      .post("/remote/folders/delete", {
        _id: _id,
      })
      .then((result) => {
        if (result.data.message === "deleted") {
          var newFolders = folders.data.slice();

          newFolders.forEach((v, i) => {
            if (v._id.toString() === _id.toString()) {
              newFolders.splice(i, 1);
            }
          });

          setFolders({ total: folders.total, data: newFolders });

          setNotify({ type: "success", message: "folder deleted" });
          searchFolders();
        }
      })
      .catch((err) => {});
  };

  const softDeleteFolder = (_id) => {
    axios
      .post("/remote/folders/soft", {
        _id: _id,
      })
      .then((result) => {
        if (result.data.message === "softdeleted") {
          var newFolders = folders.data.slice();

          var updateFolders = [];

          newFolders.forEach((v, i) => {
            var newFolder = v;
            if (v._id.toString() === _id.toString()) {
              newFolder.deleted = true;
            }
            updateFolders.push(newFolder);
          });

          setFolders({ total: folders.total, data: updateFolders });

          setNotify({ type: "success", message: "folder soft deleted" });
        }
      })
      .catch((err) => {});
  };

  const modifyFolder = async (_id, updates) => {
    var updated = await axios.post("/remote/folders/modify", { _id, updates })
      .data;
    return updated;
  };

  const updateFolder = (_id, folder_name, client) => {
    axios
      .post("/remote/folders/update", {
        _id: _id,
        name: folder_name,
        client: client,
      })
      .then((result) => {
        if (result.data.message === "updated") {
          var currentFolders = folders.data.slice();

          var newFolders = [];

          currentFolders.forEach((v, i) => {
            if (v._id.toString() === _id.toString()) {
              v.name = folder_name;
              v.client = client;
              newFolders.push(v);
            } else {
              newFolders.push(v);
            }
          });

          setFolders({ total: folders.total, data: newFolders.slice() });

          setNotify({ type: "success", message: "folder updated" });
          //searchFolders();
        }
      })
      .catch((err) => {});
  };

  const sweep = (_id) => {
    axios
      .post("/remote/sweep", {
        _id: _id,
      })
      .then((result) => {
        if (result.data.message === "done") {
          setNotify({ type: "success", message: "folder swept" });
        }
      })
      .catch((err) => {});
  };

  const upload = (_id) => {
    axios
      .post("/remote/populate", {
        _id: _id,
      })
      .then((result) => {
        if (result.data.message === "done") {
          setNotify({ type: "success", message: "files uploaded" });
        }
      })
      .catch((err) => {});
  };

  if (folders && folders.data && folders.data.length) {
    var folderMap = folders.data.map((v, i) => {
      return (
        <Grid key={"folder+" + v._id} item>
          <Folder
            modifyFolder={modifyFolder}
            setClient={setClient}
            upload={upload}
            sweep={sweep}
            getLabel={getLabel}
            folder={v}
            updateFolder={updateFolder}
            folders={folders}
            softDeleteFolder={softDeleteFolder}
            deleteFolder={deleteFolder}
            setFolders={setFolders}
          />
        </Grid>
      );
    });
  } else {
    folderMap = [];
  }

  const addClient = async (c, force) => {
    axios
      .post("/remote/clients/add", {
        name: c,
        displayName: c,
      })
      .then((result) => {
        if (result.data.message === "added") {
          setNotify({ type: "success", message: "client added" });

          //newClients.unshift(newClient);

          //props.setClients({ total: newClients.length, data: newClients.slice() });

          loadClients(result.data._id, force);
        }
      })
      .catch((err) => {});
  };

  return (
    <React.Fragment>
      {/*
      <div style={{ marginTop: theme.spacing(1) }}>
        <Pages
          setPagination={setPagination}
          isSmall={true}
          callback={paginationSearch}
          pagination={pagination}
          total={folders && folders.total ? folders.total : 0}
        />
        <div style={{ height: "4px" }}>{loading ? <Loader /> : null}</div>
      </div>
      */}

      <Grid
        xs={12}
        container
        item
        spacing={0}
        flexDirection="column"
        style={{ marginTop: theme.spacing(2) }}
      >
        <Grid item xs={12}>
          Current Page: Folders
        </Grid>
        <Grid
          xs={12}
          container
          item
          spacing={2}
          direction={"column"}
          style={{ marginTop: "0px" }}
        >
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid
                item
                xs={12}
                container
                spacing={2}
                alignItems="center"
                style={{
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <Grid item xs={12} sm={"auto"}>
                  <TextField
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    value={search_term}
                    placeholder={"search for folders by name"}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      searchFolders();
                    }}
                    variant="contained"
                    size="medium"
                    color="info"
                  >
                    Search Folders
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    onClick={() => {
                      setSearchTerm("");
                      setFolders(null);
                    }}
                    variant="contained"
                    size="medium"
                    color="sub"
                  >
                    Clear Results
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item container spacing={2} alignItems="center">
                <Grid item xs={12} sm={"auto"}>
                  <SelectClient
                    fullWidth={true}
                    setClient={setClient}
                    selectedClient={selectedClient}
                    searchFolders={searchFolders}
                    showAdd={true}
                    addClient={addClient}
                    setFolderValue={setFolderValue}
                    setLoadedFolders={setLoadedFolders}
                  />
                </Grid>

                <Grid item xs={12} sm={"auto"}>
                  <TextField
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    value={folderName}
                    placeholder={"folder name"}
                    onChange={(e) => {
                      setFolderName(e.target.value);
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <Button
                    onClick={async () => {
                      if (!folderName) {
                        setNotify({
                          type: "error",
                          message: "folder name is required",
                        });
                        return false;
                      }

                      if (!selectedClient) {
                        await addClient(folderValue, true);
                        var add = true;
                      }

                      if (!selectedClient && !add) {
                        setNotify({
                          type: "error",
                          message: "client is required",
                        });
                        return false;
                      }

                      if (!add) {
                        addFolder();
                      }
                    }}
                    variant="contained"
                    size="medium"
                    color="success"
                  >
                    Add Folder
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={archived}
                    onChange={() => {
                      var _a = !archived;
                      setArchived(_a);
                      searchFolders(null, null, sort, null, _a);
                    }}
                    inputProps={{ "aria-label": "Archived" }}
                    color="success"
                  />
                }
                label="Archived"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            {!folders ? (
              "Search results will be displayed here."
            ) : folderMap.length ? (
              <Grid spacing={2} container flexDirection="column">
                {folders && folders.total ? (
                  <Grid item>
                    <Button
                      onClick={() => {
                        if (sort === "asc") {
                          setSort("desc");
                          searchFolders(null, null, "desc");
                        } else {
                          setSort("asc");
                          searchFolders(null, null, "asc");
                        }
                      }}
                      startIcon={sort === "asc" ? <UpArrow /> : <DownArrow />}
                      color={props.theme === "dark" ? "secondary" : "primary"}
                    >
                      Sort
                    </Button>
                  </Grid>
                ) : null}

                {folderMap}
              </Grid>
            ) : (
              "No Matching Folders Found"
            )}
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(2) }}
      >
        <div style={{ height: "4px", marginBottom: "8px" }}>
          {loading ? <Loader /> : null}
        </div>
        <Pages
          setPagination={setPagination}
          align="left"
          callback={paginationSearch}
          pagination={pagination}
          total={folders && folders.total ? folders.total : 0}
        />
      </div>
      {notify.type !== "off" ? (
        <Snackbar
          open={notify !== "off"}
          autoHideDuration={2000}
          onClose={handleClose}
          TransitionComponent={Fade}
          message={notify.message}
          key={Fade.name}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={notify.type ? notify.type : "info"}
            style={{ width: "100%" }}
          >
            {notify.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
  alert: state.alert,
  clients: state.clients,
  theme: state.theme,
  client: state.client,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setClients: (clients) => dispatch(setClients(clients)),
  setClient: (client) => dispatch(setClient(client)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Folders);
