import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { connect } from "react-redux";
import {
  setUser,
  setAlert,
  setTemp,
  setGallery,
  setImage,
  setExif,
  setIptc,
} from "../actions";

import DownloadIcon from "@mui/icons-material/Download";
import { Typography, IconButton } from "@mui/material";

import { useTheme, Grid } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    "&:hover": {
      color: theme.palette.highlight.main,
    },
  },
}));

function ImageCard(props) {
  const theme = useTheme();

  var classes = useStyles();

  const handleDownload = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = imageUrl.split("/")[imageUrl.split("/").length - 1];
      link.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.cards.main,
      }}
    >
      <CardContent
        sx={{
          padding: 1,
          margin: 0,
          textAlign: "center",
        }}
      >
        <Typography variant="caption">{props.img.title}</Typography>
      </CardContent>
      <CardContent sx={{ padding: 0 }}>
        <div
          className="edit-holder"
          onClick={() => {
            props.setImage({
              image: props.img,
              total: props.total,
              idx: props.idx,
            });
          }}
        >
          <div
            className="open-edit-image"
            crossOrigin="anonymous"
            style={{
              background: 'url("' + props.image + '")no-repeat',
            }}
          />
        </div>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 0,
        }}
      >
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid
            item
            xs={6}
            sx={{
              textAlign: "center",
            }}
          >
            {!props.block ? (
              <IconButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                component="a"
                //href={"/remote/files/push?file=" + props.img.data.imageURL}
                onClick={() => {
                  handleDownload(props.img.data.imageURL);
                }}
                variant="contained"
                size="small"
                color={"button"}
                className={classes.button}
              >
                <DownloadIcon />
              </IconButton>
            ) : null}

            <IconButton
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                if (props.img.iptc) {
                  props.setIptc(props.img.iptc);
                } else {
                  props.setIptc("none");
                }
              }}
              variant="contained"
              size="small"
              color="button"
              className={classes.button}
            >
              <InfoIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              textAlign: "right",
            }}
          ></Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  alert: state.alert,
  gallery: state.gallery,
  selected: state.selected,
  exif: state.exif,
  iptc: state.iptc,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setGallery: (gallery) => dispatch(setGallery(gallery)),
  setImage: (image) => dispatch(setImage(image)),
  setExif: (exif) => dispatch(setExif(exif)),
  setIptc: (iptc) => dispatch(setIptc(iptc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageCard);
