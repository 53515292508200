import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import {
  setUser,
  setAlert,
  setTemp,
  setGallery,
  setImage,
  setIptc,
  setMeta,
} from "../actions";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography, IconButton } from "@mui/material";
//import fileDownload from "js-file-download";
//import axios from "axios";
import NextIcon from "@mui/icons-material/ArrowForward";
import PrevIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
//import ToggleButton from "@mui/material/ToggleButton";
//import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
import { makeStyles } from "@mui/styles";
//import { useTheme } from "@mui/material";
import ColorIcon from "@mui/icons-material/Palette";
//import InfoIcon from "@mui/icons-material/Info";
import TagIcon from "@mui/icons-material/LocalOffer";
import Tags from "./Tags";
import { saveAs } from "file-saver";

const useStyles = makeStyles((theme) => ({
  button: {
    "&:hover": {
      color: theme.palette.highlight.main,
    },
  },
}));

function Image(props) {
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xl");
  const [saved, setSaved] = React.useState(true);

  const [approved, setApproved] = React.useState(props.image.image.approved);

  const [localImage, setlocalImage] = React.useState(props.image);

  const saveFile = (_file) => {
    saveAs(_file, _file.split("/")[_file.split("/").length - 1]);
  };

  //const [meta, setMeta] = React.useState(false);

  React.useEffect(() => {
    setlocalImage(props.image);
    setApproved(props.image.image.approved);
  }, [props.image]);

  //const theme = useTheme();
  var classes = useStyles();

  var image = localImage.image.data.previewURL;

  const handleClose = () => {
    props.setImage(null);
    props.setMeta(false);
  };

  var next = () => {
    if (!saved) {
      if (
        !window.confirm(
          "You have unsaved meta data, are you sure you want to leave this image?"
        )
      ) {
        return false;
      }
    }

    if (props.image.idx + 1 >= props.image.total) {
      var img = Object.assign({}, props.image);
      img.idx = 0;
      img.image = props.gallery.documents[0];
      props.setImage(img);
      setlocalImage(img);
    } else {
      img = Object.assign({}, props.image);
      img.idx = props.image.idx + 1;
      img.image = props.gallery.documents[img.idx];
      props.setImage(img);
      setlocalImage(img);
    }
  };

  var prev = () => {
    if (!saved) {
      if (
        !window.confirm(
          "You have unsaved meta data, are you sure you want to leave this image?"
        )
      ) {
        return false;
      }
    }

    if (props.image.idx - 1 < 0) {
      var img = Object.assign({}, props.image);
      img.idx = props.image.total - 1;
      img.image = props.gallery.documents[props.image.total - 1];
      props.setImage(img);
      setlocalImage(img);
    } else {
      img = Object.assign({}, props.image);
      img.idx = props.image.idx - 1;
      img.image = props.gallery.documents[img.idx];
      props.setImage(img);
      setlocalImage(img);
    }
  };

  if (localImage.image && props.deleted?.includes(localImage.image._id)) {
    return null;
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={localImage?.image ? true : false}
        onClose={handleClose}
        PaperProps={{
          style: {
            height: "100%",
            backgroundColor: "#000000",
            margin: 0,
            maxHeight: "none",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <Typography color="white">{localImage.image.title}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container className="image-holder" spacing={props.meta ? 2 : 0}>
            {/*
            <Grid
              item
              xs={props.meta ? 12 : 12}
              md={props.meta ? 6 : 12}
              className="open-image"
              crossOrigin="anonymous"
              style={{
                background: props.meta
                  ? "none"
                  : 'url("' + image + '")no-repeat',
              }}
            >
              {props.meta ? (
                <img
                  src={image}
                  style={{ width: "100%", marginTop: "16px" }}
                  alt="preview"
                />
              ) : null}
            </Grid>
            */}

            <Grid
              item
              xs={props.meta ? 12 : 12}
              md={props.meta ? 6 : 12}
              className="open-image"
              crossOrigin="anonymous"
              style={{
                background:
                  'url("' + image.replace(/\+/g, "%2B") + '")no-repeat',
              }}
            ></Grid>

            {props.meta ? (
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Tags
                  saved={saved}
                  setSaved={setSaved}
                  tagSelected={(t, i) => {
                    props.tagSelected(t, i);
                    setSaved(true);
                  }}
                  folderInfo={props.folderInfo}
                />
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  {!props.viewer ? (
                    <IconButton
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      onClick={async () => {
                        var status = await props.toggleApprove(
                          approved === true
                            ? false
                            : approved === false
                            ? "reset"
                            : false,
                          localImage.image._id
                        );

                        setApproved(status === "reset" ? "" : status);

                        var _gallery = Object.assign({}, props.gallery);

                        _gallery.documents = _gallery.documents.map((g, i) => {
                          var _g = Object.assign({}, g);
                          if (g._id === localImage.image._id) {
                            _g.approved = status === "reset" ? "" : status;
                          }
                          return _g;
                        });

                        props.setGallery(_gallery);
                      }}
                      variant="contained"
                      size="small"
                      color={approved === false ? "error" : "button"}
                    >
                      <ThumbDown />
                    </IconButton>
                  ) : null}
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {!props.viewer ? (
                    <IconButton
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      onClick={async () => {
                        var status = await props.toggleApprove(
                          !approved ? true : "reset",
                          localImage.image._id
                        );

                        setApproved(status === "reset" ? "" : status);

                        var _gallery = Object.assign({}, props.gallery);

                        _gallery.documents = _gallery.documents.map((g, i) => {
                          var _g = Object.assign({}, g);
                          if (g._id === localImage.image._id) {
                            _g.approved = status === "reset" ? "" : status;
                          }
                          return _g;
                        });

                        props.setGallery(_gallery);
                      }}
                      variant="contained"
                      size="small"
                      color={approved === true ? "success" : "button"}
                    >
                      <ThumbUp />
                    </IconButton>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item container>
              <Grid
                xs={12}
                item
                sx={{
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <IconButton
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={() => {
                    var temp = Object.assign({}, props.temp);
                    temp.confirm = {
                      title: "Are you sure?",
                      cancel_text: "No",
                      text: "Do you want to delete this photo?",
                      confirm_text: "Yes",
                      callback_color: "error",
                      callback: () => {
                        props.deletePhoto(localImage.image._id);

                        var _deleted = props.deleted.slice();
                        _deleted.push(localImage.image._id);

                        props.setDeleted(_deleted);
                      },
                    };
                    props.setTemp(temp);
                  }}
                  variant="contained"
                  size="small"
                  color="button"
                  className={classes.button}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={() => {
                    props.setColoring(true);
                  }}
                  variant="contained"
                  size="small"
                  color="button"
                  className={classes.button}
                >
                  <ColorIcon />
                </IconButton>
                <IconButton
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={() => {
                    props.setMeta(!props.meta);
                  }}
                  variant="contained"
                  size="small"
                  color={props.meta ? "highlight" : "button"}
                  className={classes.button}
                >
                  <TagIcon />
                </IconButton>
                {/*
                <IconButton
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={() => {
                    if (localImage.image.iptc) {
                      props.setIptc(localImage.image.iptc);
                    } else {
                      props.setIptc("none");
                    }

                    var _temp = Object.assign({}, props.temp);
                    _temp.current_image = localImage.image;
                    props.setTemp(_temp);
                  }}
                  variant="contained"
                  size="small"
                  color="button"
                  className={classes.button}
                >
                  <InfoIcon />
                </IconButton>
                */}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              {localImage.total > 1 ? (
                <Button
                  sx={{
                    color: "#ffffff",
                  }}
                  variant="filled"
                  onClick={prev}
                  startIcon={<PrevIcon />}
                >
                  Prev
                </Button>
              ) : null}
              {!props.block ? (
                <Button
                  sx={{
                    color: "#ffffff",
                  }}
                  variant="filled"
                  component="a"
                  onClick={() => {
                    saveFile(
                      localImage.image.data.imageURL.replace(/\+/g, "%2B")
                    );
                  }}
                  startIcon={<DownloadIcon />}
                >
                  Download
                </Button>
              ) : null}
              <Button
                sx={{
                  color: "#ffffff",
                }}
                variant="filled"
                onClick={handleClose}
                startIcon={<CloseIcon />}
              >
                Close
              </Button>

              {localImage.total > 1 ? (
                <Button
                  sx={{
                    color: "#ffffff",
                  }}
                  variant="filled"
                  onClick={next}
                  startIcon={<NextIcon />}
                >
                  Next
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  alert: state.alert,
  gallery: state.gallery,
  image: state.image,
  iptc: state.iptc,
  meta: state.meta,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setGallery: (gallery) => dispatch(setGallery(gallery)),
  setImage: (image) => dispatch(setImage(image)),
  setIptc: (iptc) => dispatch(setIptc(iptc)),
  setMeta: (meta) => dispatch(setMeta(meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Image);
