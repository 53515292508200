import { combineReducers, applyMiddleware } from "redux";
import { createStore } from "redux";
import thunk from "redux-thunk";

const theme = (
  state = localStorage.getItem("mode") ? localStorage.getItem("mode") : "dark",
  action
) => {
  switch (action.type) {
    case "SET_THEME":
      localStorage.setItem("mode", action.theme);
      return action.theme;
    default:
      return state;
  }
};

const alert = (state = {}, action) => {
  switch (action.type) {
    case "SET_ALERT":
      var new_alert = {
        open: action.alert.open,
        severity: action.alert.severity
          ? action.alert.severity
          : state.severity,
        message: action.alert.message ? action.alert.message : state.message,
        vertical: action.alert.vertical ? action.alert.vertical : null,
        horizontal: action.alert.horizontal ? action.alert.horizontal : null,
      };
      return new_alert;
    default:
      return state;
  }
};

const user = (state = null, action) => {
  switch (action.type) {
    case "SET_USER":
      if (action.user && action.user.token) {
        localStorage.setItem("token", action.user.token);
      }
      return action.user;
    case "SET_USER_SOCKET":
      return Object.assign({}, state, {
        socket: action.socket,
      });
    case "SIGN_OUT":
      localStorage.removeItem("mode");
      localStorage.removeItem("token");
      localStorage.removeItem("parent_token");
      return null;
    default:
      return state;
  }
};

const temp = (state = {}, action) => {
  switch (action.type) {
    case "SET_TEMP":
      return action.temp;
    default:
      return state;
  }
};

const menu = (state = false, action) => {
  switch (action.type) {
    case "SET_MENU":
      return action.menu;
    default:
      return state;
  }
};

const image = (state = null, action) => {
  switch (action.type) {
    case "SET_IMAGE":
      return action.image;
    default:
      return state;
  }
};

const meta = (state = false, action) => {
  switch (action.type) {
    case "SET_META":
      return action.meta;
    default:
      return state;
  }
};

const selected = (state = [], action) => {
  switch (action.type) {
    case "SET_SELECTED":
      return action.selected;
    default:
      return state;
  }
};

const exif = (state = null, action) => {
  switch (action.type) {
    case "SET_EXIF":
      return action.exif;
    default:
      return state;
  }
};

const iptc = (state = null, action) => {
  switch (action.type) {
    case "SET_IPTC":
      return action.iptc;
    default:
      return state;
  }
};

const gallery = (state = { documents: [] }, action) => {
  switch (action.type) {
    case "SET_GALLERY":
      return action.gallery;
    default:
      return state;
  }
};

const clients = (state = { total: 0, data: [] }, action) => {
  switch (action.type) {
    case "SET_CLIENTS":
      return action.clients;
    default:
      return state;
  }
};

const client = (state = null, action) => {
  switch (action.type) {
    case "SET_CLIENT":
      return action.client;
    default:
      return state;
  }
};

const admin = (state = false, action) => {
  switch (action.type) {
    case "SET_ADMIN":
      return action.admin;
    default:
      return state;
  }
};

const tags = (state = {}, action) => {
  switch (action.type) {
    case "SET_TAGS":
      return action.tags;
    default:
      return state;
  }
};

const template = (state = null, action) => {
  switch (action.type) {
    case "SET_TEMPLATE":
      return action.template;
    default:
      return state;
  }
};

const alltags = (state = null, action) => {
  switch (action.type) {
    case "SET_ALLTAGS":
      return action.alltags;
    default:
      return state;
  }
};

const store = createStore(
  combineReducers({
    theme,
    alert,
    user,
    temp,
    menu,
    gallery,
    clients,
    client,
    admin,
    image,
    selected,
    exif,
    iptc,
    meta,
    tags,
    template,
    alltags,
  }),
  applyMiddleware(thunk)
);

export default store;
