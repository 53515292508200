import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { connect } from "react-redux";
import { setIptc } from "../actions";
import { Box, Button, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function Iptc(props) {
  var iptc_tree = {};

  function traverse(target, parent) {
    if (typeof target === "object") {
      for (const key in target) {
        console.log(typeof target[key]);
        console.log(target[key]);
        if (key !== "e" && typeof target[key] === "object") {
          iptc_tree[key] = {};
          traverse(target[key], key);
        } else {
          if (parent) {
            if (!iptc_tree[parent]) {
              iptc_tree[parent] = {};
            }

            iptc_tree[parent][key] = target[key];
          } else {
            iptc_tree[key] = target[key];
          }
        }
      }
    } else {
    }
  }

  if (props.iptc !== "none") {
    traverse(props.iptc);
  }

  var iptc_display = [];
  Object.keys(iptc_tree).forEach((ex, i) => {
    var cols = [];

    if (typeof iptc_tree[ex] === "object") {
      Object.keys(iptc_tree[ex]).forEach((ee, ii) => {
        cols.push(
          <Grid container key={"col" + i}>
            <Grid item xs={12}>
              <strong>{ex}</strong>
            </Grid>
          </Grid>
        );
        cols.push(
          <Grid container key={"col" + ii}>
            <Grid item xs={6}>
              <strong>{ee}</strong>
            </Grid>
            <Grid item xs={6}>
              {iptc_tree[ex][ee]}
            </Grid>
          </Grid>
        );
      });
    } else {
      cols.push(
        <Grid container key={"col" + i}>
          <Grid item xs={6}>
            <strong>{ex}</strong>
          </Grid>
          <Grid item xs={6}>
            {iptc_tree[ex]}
          </Grid>
        </Grid>
      );
    }

    var row = (
      <Grid container item key={i} style={{ marginBottom: 2 }}>
        {cols}
      </Grid>
    );
    iptc_display.push(row);
  });

  if (props.iptc === "none") {
    iptc_display = (
      <Grid container item style={{ marginBottom: 2 }}>
        <Grid item xs={12}>
          <strong>No IPTC Data</strong>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <React.Fragment>
        <Drawer
          style={{ zIndex: 1301 }}
          anchor={"right"}
          open={props.iptc ? true : false}
          onClose={() => {
            props.setIptc(null);
          }}
        >
          <Box sx={{ width: "300px", padding: 1 }}>
            <Button
              variant="contained"
              onClick={() => {
                props.setIptc(null);
              }}
              startIcon={<CloseIcon />}
              size="small"
            >
              Close
            </Button>
          </Box>
          <Box sx={{ width: "300px", padding: 1 }}>
            <Grid container item>
              <Grid item xs={12}>
                <Typography variant="caption">
                  {props.temp.current_image?.title}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {props.iptc ? (
            <Box sx={{ width: "300px", padding: 1 }}>
              <Grid container item>
                <Grid item xs={12}>
                  <Typography variant="caption">{iptc_display}</Typography>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const mapStateToProps = (state) => ({
  iptc: state.iptc,
  temp: state.temp,
});

const mapDispatchToProps = (dispatch) => ({
  setIptc: (iptc) => dispatch(setIptc(iptc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Iptc);
