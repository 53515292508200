import React from "react";
import { connect } from "react-redux";
import {
  setUser,
  setAlert,
  setTemp,
  setGallery,
  setImage,
  setSelected,
} from "../actions";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useTheme, Button, Typography } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import Pages from "../components/Pages";
import Image from "./Image";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageCard from "./ImageCard";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckIcon from "@mui/icons-material/CheckOutlined";
import UncheckIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CommentIcon from "@mui/icons-material/Comment";
import { IconButton } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
import CopyIcon from "@mui/icons-material/CopyAll";
import TagIcon from "@mui/icons-material/LocalOffer";
import FTPIcon from "@mui/icons-material/MoveUp";
import ColorIcon from "@mui/icons-material/Palette";
import Comments from "./Comments";
import Container from "@mui/material/Container";
import Clone from "./Clone";
import Tagging from "./Tagging";
import Coloring from "./Coloring";
import ColorSort from "./ColorSort";
import useMediaQuery from "@mui/material/useMediaQuery";

import Transferring from "./Transferring";
import Downloading from "./Downloading";

const CancelToken = axios.CancelToken;

let source = CancelToken.source();

function Editor(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  var matches = theme.breakpoints.only("sm");
  var isSmall = useMediaQuery(matches);

  matches = theme.breakpoints.only("xs");
  var isXSmall = useMediaQuery(matches);

  const clearSelected = props.setSelected;

  const toolbarRef = React.useCallback(
    (node) => {
      if (node !== null) {
        setOffset(node.getBoundingClientRect().height);
      }

      clearSelected([]);
    },
    [clearSelected]
  );

  const [disabled, setDisabled] = React.useState(false);
  const [auto, setAuto] = React.useState(false);
  const [folderInfo, setFolderInfo] = React.useState(null);

  const [comments, setComments] = React.useState("");

  const [approved, setApproved] = React.useState([]);
  const [deleted, setDeleted] = React.useState([]);

  const approvedRef = React.useRef(approved);

  const [colorSort, setColorSort] = React.useState(false);

  const commentRef = React.useRef(comments);

  const [cloning, setCloning] = React.useState(false);
  const [offset, setOffset] = React.useState(0);

  const [lastFile, setLastFile] = React.useState(null);

  const [once, setOnce] = React.useState(false);

  const handleChange = (event, newApproved) => {
    setApproved(newApproved.slice());
    approvedRef.current = newApproved.slice();
    searchClients(null, pagination, newApproved.slice());
  };

  const handleAuto = (event) => {
    setAuto(!auto);
  };

  var ss = props.setSelected;

  React.useEffect(() => {
    if (!once) {
      setOnce(true);

      ss([]);

      return () => {
        if (window.auto) {
          clearTimeout(window.auto);
          delete window.auto;
        }

        if (window.old_title) {
          document.title = window.old_title;
        }
      };
    }
  }, [ss, once]);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiSwitch-track": {
      backgroundColor: "#39393D",
      opacity: 1,
    },
  }));

  let { folder, client, edit, download } = useParams();

  const [loaded, setLoaded] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const loadingRef = React.useRef(loading);
  const [search_term, setSearchTerm] = React.useState("");
  const [downloadLink, setDownloadLink] = React.useState("");
  const [downloadTitle, setDownloadTitle] = React.useState("");
  const [zipping, setZipping] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [copying, setCopying] = React.useState(false);
  const [sort, setSort] = React.useState("nameup");
  const [color, setColor] = React.useState("none");
  const [allow, setAllow] = React.useState(false);

  const [tagging, setTagging] = React.useState(false);
  const [changing, setChanging] = React.useState(false);

  const [coloring, setColoring] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);

  const [transferring, setTransferring] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);

  const [pagination, setPagination] = React.useState({
    page: 1,
    skip: 0,
    limit: 100,
    max: 100,
  });

  const handleSort = async (e) => {
    setSort(e.target.value);
    setLoaded(false);
  };

  const handleColor = async (col) => {
    setColor(col);
    setLoaded(false);
  };

  const toggleApprove = async (action, id) => {
    var result = await axios.post("/photos/toggle", {
      action: action,
      _id: id,
    });

    return result.data.approved;
  };

  const queryParams = new URLSearchParams(window.location.search);
  const title = queryParams.get("title");

  const downloadSelected = async () => {
    setZipping(true);
    var dl = await axios.post(
      "https://upload.lestudio.photos/remote/files/download",
      {
        folder: folder,
        file: title,
        selected: props.selected,
      }
    );
    setZipping(false);

    setDownloadLink(dl.data.downloadURL);
    setDownloadTitle(dl.data.downloadTitle);
    setLastFile(dl.data.lastFile);
  };

  const downloadAll = async () => {
    setZipping(true);
    var dl = await axios.post(
      "https://upload.lestudio.photos/remote/files/downloadfolder",
      {
        folder: folder,
        file: title,
      }
    );
    setZipping(false);

    setDownloadLink(dl.data.downloadURL);
    setDownloadTitle(dl.data.downloadTitle);
    setLastFile({
      link: dl.data.downloadURL,
      title: dl.data.downloadTitle,
    });
  };

  const deleteAll = async () => {
    setDeleting(true);
    await axios.post("/remote/files/deleteall", {
      folder: folder,
    });
    setDeleting(false);
    searchClients(null, pagination);
  };

  const deleteSelected = async () => {
    setDeleting(true);
    await axios.post("/remote/files/deleteselected", {
      folder: folder,
      selected: props.selected,
    });
    setDeleting(false);
    searchClients(null, pagination);
  };

  const copyAll = async () => {
    setCopying(true);
    await axios
      .post("https://upload.lestudio.photos/remote/files/clonefolder", {
        folder: folder,
        name: window.cloneName,
        id: window.cloneID,
      })
      .catch(() => {
        setCloning(false);
        setCopying(false);
      });
    setCopying(false);
    setCloning(false);
  };

  const copySelected = async () => {
    setCopying(true);
    await axios
      .post("https://upload.lestudio.photos/remote/files/clone", {
        folder: folder,
        selected: props.selected,
        name: window.cloneName,
        id: window.cloneID,
      })
      .catch(() => {
        setCloning(false);
        setCopying(false);
      });
    setCopying(false);
    setCloning(false);
  };

  const checkClone = async () => {
    if (props.selected.length) {
      copySelected();
    } else {
      copyAll();
    }
  };

  const tagAll = async (tags) => {
    setChanging(true);
    await axios
      .post("/remote/files/tagfolder", {
        folder: folder,
        name: window.cloneName,
        tags: tags,
      })
      .then(() => {
        setChanging(false);
        setTagging(false);
      })
      .catch(() => {
        setTagging(false);
        setChanging(false);
      });
    setLoaded(false);
  };

  const tagSelected = async (tags, targets) => {
    setChanging(true);
    console.log(tags);
    await axios
      .post("/remote/files/tag", {
        folder: folder,
        selected: targets ? targets : props.selected,
        tags: tags,
      })
      .then(() => {
        setChanging(false);
        setTagging(false);
      })
      .catch(() => {
        setTagging(false);
        setChanging(false);
      });
    setLoaded(false);
  };

  const checkTagging = async (t, targets) => {
    var _sel = targets ? targets : props.selected;
    if (_sel.length) {
      tagSelected(t, targets ? [targets] : null);
    } else {
      tagAll(t);
    }
  };

  const transferAll = async (details) => {
    setUploading(true);
    await axios
      .post("/remote/files/ftpfolder", {
        folder: folder,
        details: details,
      })
      .catch(() => {
        setTransferring(false);
        setUploading(false);
      });
    setUploading(false);
    setTransferring(false);
  };

  const transferSelected = async (details) => {
    setUploading(true);
    await axios
      .post("/remote/files/ftp", {
        folder: folder,
        selected: props.selected,
        details: details,
      })
      .catch(() => {
        setTransferring(false);
        setUploading(false);
      });
    setUploading(false);
    setTransferring(false);
  };

  const checkTransferring = async (t) => {
    if (props.selected.length) {
      transferSelected(t);
    } else {
      transferAll(t);
    }
  };

  const checkDownloading = async (t) => {
    if (props.selected.length) {
      downloadSelected(t);
    } else {
      downloadAll(t);
    }
  };

  const colorAll = async (color) => {
    setUpdating(true);
    await axios
      .post("/remote/files/colorfolder", {
        folder: folder,
        name: window.cloneName,
        color: color,
      })
      .catch(() => {
        setColoring(false);
        setUpdating(false);
      });
    setUpdating(false);
    setColoring(false);
    var _gallery = Object.assign({}, props.gallery);

    _gallery.documents.map((g, i) => {
      var _g = Object.assign({}, g);
      _g.color = color;
      return _g;
    });

    props.setSelected([]);
    props.setGallery(_gallery);
  };

  const colorSelected = async (color, targets) => {
    setUpdating(true);
    await axios
      .post("/remote/files/color", {
        folder: folder,
        selected: targets ? targets : props.selected,
        color: color,
      })
      .catch(() => {
        setColoring(false);
        setUpdating(false);
      });
    setUpdating(false);
    setColoring(false);
    var _gallery = Object.assign({}, props.gallery);

    _gallery.documents = props.gallery.documents.slice();

    var _sel = targets ? targets : props.selected;

    var _docs = _gallery.documents.map((g, i) => {
      var _g = Object.assign({}, g);
      if (_sel.includes(g._id)) {
        _g.color = color;
      }
      return _g;
    });
    _gallery.documents = _docs.slice();
    props.setSelected([]);
    props.setGallery(_gallery);
  };

  const checkColoring = async (t, targets) => {
    var _sel = targets ? targets : props.selected;
    if (_sel.length) {
      colorSelected(t, targets ? [targets] : null);
    } else {
      colorAll(t);
    }
  };

  const selectAll = async (which) => {
    var _new = [];
    for (const image of props.gallery.documents) {
      _new.push(image._id);
    }

    var _selected = props.selected.slice();

    _new.forEach((_id) => {
      if (_selected.includes(_id)) {
        if (which === "off") {
          _selected.splice(_selected.indexOf(_id), 1);
        }
      } else {
        if (which === "on") {
          _selected.push(_id);
        }
      }
    });

    props.setSelected(_selected);
    setDownloadLink("");
    setDownloadTitle("");
  };

  const toggleSelected = (_id) => {
    var _selected = props.selected.slice();

    if (_selected.includes(_id)) {
      _selected.splice(_selected.indexOf(_id), 1);
    } else {
      _selected.push(_id);
    }
    props.setSelected(_selected);
    setDownloadLink("");
    setDownloadTitle("");
  };

  const paginationSearch = async (pagination_settings) => {
    searchClients(null, pagination_settings);
  };

  const searchClients = (filter, pagination_settings, _approved) => {
    source.cancel("cancelled");
    source = CancelToken.source();

    if (!loading) {
      setLoading(true);
    }

    if (!pagination_settings) {
      pagination_settings = pagination;
    }

    axios
      .post("/photos/search", {
        query: {
          search_term: filter && filter === "all" ? "" : search_term,
          page: 0,
          pagination: pagination_settings,
        },
        deleted: true,
        client: client,
        folder: folder,
        edit_pin: edit,
        download_pin: download,
        sort: sort,
        color: color,
        approved: _approved ? _approved : approved,
        viewer: props.viewer,
      })
      .then((result) => {
        if (result.data.folder) {
          setFolderInfo(result.data.folder);

          if (result.data.folder.lastFile) {
            setLastFile({
              link: result.data.folder.lastFile.link,
              title: result.data.folder.lastFile.title,
            });
          }

          if (result.data.folder.comments) {
            setComments(result.data.folder.comments);
          }
        }

        if (!window.old_title) {
          window.old_title = document.title;
        }

        if (result.data.downloadable && result.data.downloadable === true) {
          setAllow(true);
        } else if (!result.data.downloadable && props.viewer) {
          setAllow(false);
        }

        document.title = window.old_title + " | " + result.data.folder.name;

        var new_gallery = Object.assign({}, props.gallery);
        new_gallery.documents = result.data.data;
        new_gallery.total = result.data.total;
        props.setGallery(new_gallery);

        if (filter && filter === "all") {
          setSearchTerm("");
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err && err.message === "cancelled") {
        } else {
          setLoading(false);
        }
      });
  };

  const deletePhoto = async (_id) => {
    await axios.post("/remote/files/deletephoto", {
      _id: _id,
    });
  };

  var _admin = props.admin;

  React.useEffect(() => {
    var adminStored = localStorage.getItem("admin") ? true : false;

    var load = async () => {
      //console.log("load");
      if (loadingRef.current) {
        return false;
      }

      source.cancel("cancelled");
      source = CancelToken.source();

      if (!loadingRef.current) {
        loadingRef.current = true;
      }

      var pagination_settings = pagination;

      axios
        .post("/photos/search", {
          query: {
            search_term: search_term,
            page: 0,
            pagination: pagination_settings,
          },
          deleted: true,
          client: client,
          folder: folder,
          edit_pin: edit,
          download_pin: download,
          sort: sort,
          color: color,
          approved: approvedRef.current,
          viewer: props.viewer,
          admin: adminStored ? true : false,
        })
        .then((result) => {
          if (
            (result.data.folder &&
              result.data.folder.disable_links &&
              !adminStored) ||
            result.data.status === false
          ) {
            setDisabled(true);
            setChecked(true);
            return false;
          }

          if (result.data.folder) {
            setFolderInfo(result.data.folder);

            if (result.data.folder.lastFile) {
              setLastFile({
                link: result.data.folder.lastFile.link,
                title: result.data.folder.lastFile.title,
              });
            }

            if (result.data.folder.comments) {
              setComments(result.data.folder.comments);
            }
          }

          window.old_title = document.title;
          document.title = document.title + " | " + result.data.folder.name;
          var new_gallery = Object.assign({}, props.gallery);
          new_gallery.documents = result.data.data;
          new_gallery.total = result.data.total;
          props.setGallery(new_gallery);

          setLoading(false);
          loadingRef.current = false;
          setChecked(true);

          if (result.data.downloadable && result.data.downloadable === true) {
            setAllow(true);
          } else if (!result.data.downloadable && props.viewer) {
            setAllow(false);
          }

          if (auto) {
            if (window.auto) {
              clearTimeout(window.auto);
            }
            window.auto = setTimeout(load, 15000);
          }
        })
        .catch((err) => {
          if (err && err.message === "cancelled") {
          } else {
            setLoading(false);
            loadingRef.current = false;
            if (auto) {
              if (window.auto) {
                clearTimeout(window.auto);
              }
              window.auto = setTimeout(load, 15000);
            }
          }
        });
    };

    if (!auto && window.auto) {
      clearTimeout(window.auto);
      delete window.auto;
    }

    if (!loaded) {
      setLoaded(true);
      setTimeout(load, 1);
    }
  }, [
    props,
    loaded,
    folder,
    client,
    loading,
    pagination,
    search_term,
    edit,
    sort,
    download,
    auto,
    approved,
    color,
    _admin,
  ]);

  if (!checked) {
    return (
      <Box
        sx={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          p: 4,
        }}
      >
        <CircularProgress color="success" />
      </Box>
    );
  }

  if (disabled && !props.admin) {
    return (
      <React.Fragment>
        <Grid item container style={{ padding: 2 }}>
          Gallery Links Disabled
        </Grid>
      </React.Fragment>
    );
  }

  var selected_count =
    props.selected.length === props.gallery.total
      ? "All"
      : props.selected.length;

  return (
    <React.Fragment>
      <Grid
        container
        item
        sx={{
          mt: 0,
          zIndex: isMobile ? "auto" : 1299,
          backgroundColor:
            props.theme === "light"
              ? "#ffffff !important"
              : "#161616 !important",
          position: isMobile ? "relative" : "fixed",
          left: 0,
          ml: 0,
          mr: 0,
          width: "100%",
          top: isMobile ? "8px" : isXSmall ? "56px" : isSmall ? "64px" : "69px",
        }}
        alignItems="center"
        flexDirection="row"
        spacing={1}
        ref={toolbarRef}
      >
        <Container maxWidth="xl">
          <Grid
            container
            item
            sx={{
              mt: 0,
              zIndex: 1299,
              backgroundColor:
                props.theme === "light"
                  ? "#ffffff !important"
                  : "#161616 !important",
              ml: 0,
              mr: 0,
              width: "100%",
              pb: 1,
            }}
            alignItems="center"
            flexDirection="row"
            spacing={1}
          >
            <Grid item style={{ margin: "8px 8px 0px 0px" }}>
              Selected {props.selected.length}/
              {props.gallery.total ? props.gallery.total : 0}
            </Grid>
            <Grid item xs={12} lg={6}>
              <Button
                sx={{
                  ml: 0,
                  mr: 1,
                  mt: 1,
                  opacity:
                    props.selected.length === props.gallery.total ? 0.25 : 1,
                }}
                onClick={() => {
                  selectAll("on");
                }}
                variant="contained"
                size="small"
                startIcon={<CheckIcon />}
                disabled={props.selected.length === props.gallery.total}
              >
                Page
              </Button>
              <Button
                onClick={() => {
                  selectAll("off");
                }}
                variant="contained"
                size="small"
                startIcon={<UncheckIcon />}
                sx={{
                  ml: 0,
                  mr: 1,
                  mt: 1,
                  opacity: !props.selected.length ? 0.25 : 1,
                }}
                disabled={!props.selected.length}
              >
                Page
              </Button>
              {allow ? (
                downloadLink && 1 === 2 ? (
                  <Button
                    component="a"
                    href={downloadLink}
                    download={"Download .zip file"}
                    variant="contained"
                    size="small"
                    color="success"
                    sx={{ mt: 1 }}
                    downloadTitle={downloadTitle}
                  >
                    Download .zip file
                  </Button>
                ) : props.selected.length ? (
                  <LoadingButton
                    onClick={() => {
                      setDownloading(true);
                    }}
                    variant="contained"
                    size="small"
                    loading={zipping}
                    loadingPosition="start"
                    color={lastFile ? "success" : "primary"}
                    startIcon={<DownloadIcon />}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {zipping ? "Preparing" : "(" + selected_count + ")"}
                  </LoadingButton>
                ) : (
                  /*
                  <LoadingButton
                    onClick={downloadAll}
                    variant="contained"
                    size="small"
                    loading={zipping}
                    loadingPosition="start"
                    startIcon={<DownloadIcon />}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {zipping ? "Preparing" : "(All)"}
                  </LoadingButton>
                  */
                  <LoadingButton
                    onClick={() => {
                      setDownloading(true);
                    }}
                    variant="contained"
                    size="small"
                    color={lastFile ? "success" : "primary"}
                    loading={zipping}
                    loadingPosition="start"
                    startIcon={<DownloadIcon />}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {zipping ? "Preparing" : "(All)"}
                  </LoadingButton>
                )
              ) : null}

              {props.admin ? (
                props.selected.length ? (
                  <LoadingButton
                    onClick={() => {
                      setCloning(true);
                    }}
                    variant="contained"
                    size="small"
                    loading={copying}
                    loadingPosition="start"
                    startIcon={<CopyIcon />}
                    sx={{ mr: 1, mt: 1 }}
                  >
                    {"(" + selected_count + ")"}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    onClick={() => {
                      setCloning(true);
                    }}
                    variant="contained"
                    size="small"
                    loading={copying}
                    loadingPosition="start"
                    startIcon={<CopyIcon />}
                    sx={{ mr: 1, mt: 1 }}
                  >
                    {"(All)"}
                  </LoadingButton>
                )
              ) : null}

              {props.admin ? (
                props.selected.length ? (
                  <LoadingButton
                    onClick={() => {
                      setTagging(true);
                    }}
                    variant="contained"
                    size="small"
                    loading={changing}
                    loadingPosition="start"
                    startIcon={<TagIcon />}
                    sx={{ mr: 1, mt: 1 }}
                  >
                    {"(" + selected_count + ")"}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    onClick={() => {
                      setTagging(true);
                    }}
                    variant="contained"
                    size="small"
                    loading={changing}
                    loadingPosition="start"
                    startIcon={<TagIcon />}
                    sx={{ mr: 1, mt: 1 }}
                  >
                    {"(All)"}
                  </LoadingButton>
                )
              ) : null}

              {props.admin ? (
                props.selected.length ? (
                  <LoadingButton
                    onClick={() => {
                      setColoring(true);
                    }}
                    variant="contained"
                    size="small"
                    loading={changing}
                    loadingPosition="start"
                    startIcon={<ColorIcon />}
                    sx={{ mr: 1, mt: 1 }}
                  >
                    {"(" + selected_count + ")"}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    onClick={() => {
                      setColoring(true);
                    }}
                    variant="contained"
                    size="small"
                    loading={changing}
                    loadingPosition="start"
                    startIcon={<ColorIcon />}
                    sx={{ mr: 1, mt: 1 }}
                  >
                    {"(All)"}
                  </LoadingButton>
                )
              ) : null}

              {props.admin ? (
                props.selected.length ? (
                  <LoadingButton
                    onClick={() => {
                      setTransferring(true);
                    }}
                    variant="contained"
                    size="small"
                    loading={uploading}
                    loadingPosition="start"
                    startIcon={<FTPIcon />}
                    sx={{ mr: 1, mt: 1 }}
                  >
                    {"(" + selected_count + ")"}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    onClick={() => {
                      setTransferring(true);
                    }}
                    variant="contained"
                    size="small"
                    loading={uploading}
                    loadingPosition="start"
                    startIcon={<FTPIcon />}
                    sx={{ mr: 1, mt: 1 }}
                  >
                    {"(All)"}
                  </LoadingButton>
                )
              ) : null}
              {props.admin ? (
                props.selected.length ? (
                  <LoadingButton
                    onClick={() => {
                      var temp = Object.assign({}, props.temp);
                      temp.confirm = {
                        title: "Are you sure?",
                        cancel_text: "No, Cancel",
                        confirm_text: "Yes, Delete",
                        callback_color: "warning",
                        text: "Do you want to delete the selected photos?",
                        callback: deleteSelected,
                      };
                      props.setTemp(temp);
                    }}
                    variant="contained"
                    size="small"
                    loading={deleting}
                    loadingPosition="start"
                    startIcon={<DeleteIcon />}
                    color="error"
                    sx={{ mr: 0, mt: 1 }}
                  >
                    {"(" + selected_count + ")"}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    onClick={() => {
                      var temp = Object.assign({}, props.temp);
                      temp.confirm = {
                        title: "Are you sure?",
                        cancel_text: "No, Cancel",
                        confirm_text: "Yes, Delete",
                        callback_color: "warning",
                        text: "Do you want to delete all photos?",
                        callback: deleteAll,
                      };
                      props.setTemp(temp);
                    }}
                    variant="contained"
                    size="small"
                    loading={deleting}
                    loadingPosition="start"
                    startIcon={<DeleteIcon />}
                    color="error"
                    sx={{ mr: 0, mt: 1 }}
                  >
                    {"(All)"}
                  </LoadingButton>
                )
              ) : null}
            </Grid>

            {!props.viewer ? (
              <Grid
                item
                style={{
                  textAlign: "center",
                  display: "flex",
                }}
                xs={12}
                lg={6}
                md={6}
                container
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {!props.viewer ? (
                    <Grid
                      item
                      alignItems="center"
                      justifyContent="center"
                      alignContent="center"
                      style={{ display: "flex" }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={!auto}
                              onChange={handleAuto}
                              inputProps={{ "aria-label": "Edit" }}
                              color="success"
                            />
                          }
                          label="Edit"
                        />
                      </FormGroup>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}

            <Grid
              item
              style={{
                flex: 1,
                textAlign: "right",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                alignContent: "center",
              }}
              xs={12}
              lg={props.viewer ? 6 : 6}
              container
            >
              {!props.viewer ? (
                <LoadingButton
                  onClick={() => {
                    var temp = Object.assign({}, props.temp);
                    temp.confirm = {
                      title: "Submit Comments",
                      cancel_text: "No, Cancel",
                      confirm_text: "Yes, Send",
                      callback_color: "warning",
                      text: (
                        <Comments
                          setComments={setComments}
                          comments={comments}
                          folderInfo={folderInfo}
                          commentRef={commentRef}
                        />
                      ),

                      callback: () => {
                        axios
                          .post("/remote/folders/comments/", {
                            comments: commentRef.current,
                            folder: folder,
                          })
                          .then((result) => {
                            var temp = Object.assign({}, props.temp);
                            temp.confirm = null;
                            props.setTemp(temp);
                            props.setAlert({
                              open: true,
                              severity: "success",
                              message: "Comments submitted",
                            });
                          })
                          .catch((err) => {
                            props.setAlert({
                              open: true,
                              severity: "error",
                              message: "Comments not submitted",
                            });
                          });
                      },
                    };
                    props.setTemp(temp);
                  }}
                  variant="contained"
                  size="small"
                  loading={false}
                  loadingPosition="start"
                  startIcon={<CommentIcon />}
                  color="info"
                  sx={{ mr: 1, mt: 0 }}
                >
                  Submit
                </LoadingButton>
              ) : null}
              {!props.viewer ? (
                <ToggleButtonGroup
                  color="primary"
                  value={approved}
                  onChange={handleChange}
                  aria-label="Platform"
                  sx={{ mr: 2 }}
                >
                  <ToggleButton size="small" value="up" color="success">
                    <ThumbUp
                      color={
                        approved.includes("up")
                          ? "success"
                          : props.theme === "dark"
                          ? "button"
                          : "secondary"
                      }
                    />
                  </ToggleButton>
                  <ToggleButton size="small" value="down" color="error">
                    <ThumbDown
                      color={
                        approved.includes("down")
                          ? "error"
                          : props.theme === "dark"
                          ? "button"
                          : "secondary"
                      }
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              ) : null}

              <Box
                sx={{
                  mr: 1,
                }}
              >
                <IconButton
                  //disableRipple
                  //disableFocusRipple
                  //disableTouchRipple
                  onClick={() => {
                    setColorSort(true);
                  }}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  <ColorIcon />
                </IconButton>
              </Box>

              <FormControl
                sx={{
                  "& .MuiSvgIcon-root": {
                    color:
                      props.theme === "dark"
                        ? "#ffffff !important"
                        : "#000000 !important",
                  },
                }}
              >
                <Select
                  value={sort}
                  onChange={handleSort}
                  variant="outlined"
                  size="small"
                >
                  <MenuItem value={"dateup"}>Date Added (asc)</MenuItem>
                  <MenuItem value={"datedown"}>Date Added (desc)</MenuItem>
                  <MenuItem value={"nameup"}>Name (asc)</MenuItem>
                  <MenuItem value={"namedown"}>Name (desc)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid
        xs={12}
        container
        item
        spacing={2}
        flexDirection="row"
        style={{
          marginTop: isMobile ? 0 : offset,
          marginBottom: theme.spacing(2),
        }}
      >
        <Grid style={{ marginTop: 0 }} item xs={12}>
          {folderInfo ? (
            <Typography variant="h6">{folderInfo.name}</Typography>
          ) : null}
        </Grid>

        {checked && !props.gallery.total ? (
          <React.Fragment>
            <Grid
              container
              item
              sx={{ mt: 1, height: "48px" }}
              alignItems="center"
              flexDirection="row"
            >
              No images found.
            </Grid>
          </React.Fragment>
        ) : null}

        {props.gallery.documents.map((p, idx) => {
          var image = p.data.thumbnailURL;

          return (
            <Grid
              item
              xs={12}
              md={3}
              lg={2}
              key={idx}
              sx={{
                opacity: deleted.includes(p._id) ? 0.15 : 1,
              }}
            >
              <ImageCard
                key={p._id}
                toggleSelected={toggleSelected}
                toggleApprove={toggleApprove}
                approved={p.approved}
                image={image}
                img={p}
                deletePhoto={deletePhoto}
                viewer={props.viewer}
                idx={idx}
                total={props.gallery.documents.length}
                allow={allow}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid xs={12} container item style={{ marginBottom: 1 }}>
        <Pages
          setPagination={setPagination}
          align="center"
          callback={paginationSearch}
          pagination={pagination}
          total={props.gallery && props.gallery.total ? props.gallery.total : 0}
        />
      </Grid>
      {props.image ? (
        <Image
          toggleApprove={toggleApprove}
          setApprove={setApproved}
          viewer={props.viewer}
          colorSelected={colorSelected}
          setColoring={setColoring}
          handleChange={handleChange}
          approved={props.image.approved}
          deletePhoto={deletePhoto}
          deleted={deleted}
          setDeleted={setDeleted}
          tagSelected={tagSelected}
          folderInfo={folderInfo}
        />
      ) : null}
      {cloning ? (
        <Clone
          copySelected={copySelected}
          copyAll={copyAll}
          copying={copying}
          cloning={cloning}
          setCopying={setCopying}
          setCloning={setCloning}
          callback={checkClone}
          client={client}
          folderInfo={folderInfo}
        />
      ) : null}
      {tagging ? (
        <Tagging
          tagSelected={tagSelected}
          tagAll={tagAll}
          changing={changing}
          tagging={tagging}
          setChanging={setChanging}
          setTagging={setTagging}
          callback={checkTagging}
          folderInfo={folderInfo}
        />
      ) : null}

      {transferring ? (
        <Transferring
          transferSelected={transferSelected}
          transferAll={transferAll}
          uploading={uploading}
          transferring={transferring}
          setUploading={setUploading}
          setTransferring={setTransferring}
          callback={checkTransferring}
        />
      ) : null}

      {coloring ? (
        <Coloring
          colorSelected={colorSelected}
          colorAll={colorAll}
          updating={updating}
          coloring={coloring}
          setUpdating={setUpdating}
          setColoring={setColoring}
          callback={checkColoring}
        />
      ) : null}

      {colorSort ? (
        <ColorSort
          colorSort={colorSort}
          setColorSort={setColorSort}
          colorSelected={colorSelected}
          colorAll={colorAll}
          updating={updating}
          coloring={coloring}
          setUpdating={setUpdating}
          setColoring={setColoring}
          callback={checkColoring}
          handleColor={handleColor}
        />
      ) : null}

      {downloading ? (
        <Downloading
          zipping={zipping}
          downloadSelected={downloadSelected}
          downloadAll={downloadAll}
          downloading={downloading}
          setDownloading={setDownloading}
          callback={checkDownloading}
          downloadTitle={downloadTitle}
          downloadLink={downloadLink}
          lastFile={lastFile}
          setLastFile={setLastFile}
          folderInfo={folderInfo}
        />
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  alert: state.alert,
  gallery: state.gallery,
  image: state.image,
  selected: state.selected,
  theme: state.theme,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setGallery: (gallery) => dispatch(setGallery(gallery)),
  setImage: (image) => dispatch(setImage(image)),
  setSelected: (selected) => dispatch(setSelected(selected)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
