import React from "react";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setGallery } from "../actions";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import Gallery from "../gallery/Gallery";
import axios from "axios";
import { useParams } from "react-router-dom";

function Display(props) {
  let { folder } = useParams();

  let { client } = useParams();

  const theme = useTheme();

  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    var load = async () => {
      setLoaded(true);

      axios
        .get("/photos/gallery/" + client + "/" + folder)
        .then((result) => {
          var new_gallery = Object.assign({}, props.gallery);
          new_gallery.documents = result.data.documents;

          props.setGallery(new_gallery);
        })
        .catch((err) => {});
    };

    if (!loaded) {
      load();
    }
  }, [props, loaded, folder, client]);

  return (
    <Grid
      xs={12}
      container
      item
      spacing={0}
      flexDirection="column"
      style={{ marginTop: theme.spacing(2) }}
    >
      <Grid item xs={12}>
        <Gallery />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  alert: state.alert,
  gallery: state.gallery,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setGallery: (gallery) => dispatch(setGallery(gallery)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Display);
