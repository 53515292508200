import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { connect } from "react-redux";
import { setExif } from "../actions";
import { Box, Button, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function Exif(props) {
  var exif_tree = {};

  function traverse(target, parent) {
    for (const key in target) {
      if (key !== "e" && typeof target[key] === "object") {
        exif_tree[key] = {};
        traverse(target[key], key);
      } else {
        if (parent) {
          if (!exif_tree[parent]) {
            exif_tree[parent] = {};
          }

          exif_tree[parent][key] = target[key];
        } else {
          exif_tree[key] = target[key];
        }
      }
    }
  }

  if (props.exif !== "none") {
    traverse(props.exif);
  }

  var exif_display = [];
  Object.keys(exif_tree).forEach((ex, i) => {
    var cols = [];
    Object.keys(exif_tree[ex]).forEach((ee, ii) => {
      cols.push(
        <Grid container key={"col" + ii}>
          <Grid item xs={6}>
            <strong>{ee}</strong>
          </Grid>
          <Grid item xs={6}>
            {exif_tree[ex][ee]}
          </Grid>
        </Grid>
      );
    });

    var row = (
      <Grid container item key={i} style={{ marginBottom: 2 }}>
        <Grid item xs={12}>
          <strong>{ex}</strong>
        </Grid>
        {cols}
      </Grid>
    );
    exif_display.push(row);
  });

  if (props.exif === "none") {
    exif_display = (
      <Grid container item style={{ marginBottom: 2 }}>
        <Grid item xs={12}>
          <strong>No EXIF Data</strong>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={"right"}
          open={props.exif ? true : false}
          onClose={() => {
            props.setExif(null);
          }}
        >
          <Box sx={{ width: "300px", padding: 1 }}>
            <Button
              variant="contained"
              onClick={() => {
                props.setExif(null);
              }}
              startIcon={<CloseIcon />}
              size="small"
            >
              Close
            </Button>
          </Box>
          {props.exif ? (
            <Box sx={{ width: "300px", padding: 1 }}>
              <Grid container item>
                <Grid item xs={12}>
                  <Typography variant="caption">{exif_display}</Typography>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const mapStateToProps = (state) => ({
  exif: state.exif,
});

const mapDispatchToProps = (dispatch) => ({
  setExif: (exif) => dispatch(setExif(exif)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Exif);
