import React from "react";
import Button from "@mui/material/Button";
//import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { setTemp, setGallery } from "../actions";
import Grid from "@mui/material/Grid";
import Colors from "./Colors";

function Confirm(props) {
  const handleClose = () => {
    props.setColorSort(false);
  };

  var setColor = async (color) => {
    props.handleColor(color);
    props.setColorSort(false);
  };

  if (!props.colorSort) {
    return null;
  }

  return (
    <Dialog
      open={props.colorSort}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
      scroll="paper"
    >
      <DialogTitle style={{ paddingBottom: "8px" }}>
        Filter By Color
      </DialogTitle>
      <DialogContent style={{ paddingTop: "0px" }}>
        <Grid container spacing={0}>
          <Colors setColor={setColor} />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
  gallery: state.gallery,
  image: state.image,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
  setGallery: (gallery) => dispatch(setGallery(gallery)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
