import ImagesList from "./components/imagesList/ImagesList";
import Modal from "./components/Modal";
import MainNotification from "./components/MainNotification";
import Loading from "./components/Loading";
import AuthContext from "./context/AuthContext";

function Gallery() {
  return (
    <AuthContext>
      <Loading />
      <Modal />
      <MainNotification />
      <ImagesList />
    </AuthContext>
  );
}

export default Gallery;
