import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/DeleteForever";
//import SoftDeleteIcon from "@mui/icons-material/Delete";
import { connect } from "react-redux";
import { setTemp } from "../../actions";
import Divider from "@mui/material/Divider";
import SaveIcon from "@mui/icons-material/Save";
import Fab from "@mui/material/Fab";
import UserIcon from "@mui/icons-material/People";
import { useNavigate } from "react-router-dom";

function Client(props) {
  const [clientName, setClientName] = React.useState(props.client.name);
  const [displayName, setDisplayName] = React.useState(
    props.client.displayName
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    setClientName(props.client.name);
    setDisplayName(props.client.displayName);
  }, [props.clients, props.client]);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid item xs={12} sm={"auto"}>
          <TextField
            color="secondary"
            variant="outlined"
            value={clientName}
            placeholder={"clientname"}
            onChange={(e) => {
              setClientName(e.target.value);
            }}
            size="small"
            disabled={props.client.deleted}
            fullWidth
          />
        </Grid>
        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              navigate("/client/" + props.client._id);
            }}
            variant="contained"
            size="small"
            color="info"
            disabled={props.client.deleted}
            title="Admin Page For Client"
          >
            <UserIcon />
          </Fab>
        </Grid>
        {/*
        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              if (props.client) {
                if (navigator.share) {
                  navigator
                    .share({
                      title: props.client.name,
                      text: "Shared Gallery",
                      url: process.env.REACT_APP_BASE + props.client._id,
                    })
                    .then(() => console.log("Successful share"))
                    .catch((error) => console.log("Error sharing", error));
                } else {
                  window.open("/client/" + props.client._id);
                }
              } else {
                if (navigator.share) {
                  navigator
                    .share({
                      title: props.client.name,
                      text: "Shared Gallery",
                      url: process.env.REACT_APP_BASE + props.client._id,
                    })
                    .then(() => console.log("Successful share"))
                    .catch((error) => console.log("Error sharing", error));
                } else {
                  window.open("/client/" + props.client._id);
                }
              }
            }}
            variant="contained"
            size="small"
            color="info"
            disabled={props.client.deleted || !props.client}
          >
            <ShareIcon />
          </Fab>
        </Grid>
        */}
        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              props.updateClient(props.client._id, clientName, displayName);
            }}
            variant="contained"
            size="small"
            color="success"
            disabled={props.client.deleted}
            title="Save Client"
          >
            <SaveIcon />
          </Fab>
        </Grid>
        {/*
        <Grid item xs={"auto"} sm={"auto"}>
          <Button
            onClick={() => {
              var temp = Object.assign({}, props.temp);
              temp.confirm = {
                title: "Are you sure?",
                cancel_text: "No, Cancel",
                confirm_text: "Yes, Delete",
                callback_color: "warning",
                text: "Do you want to soft delete this client?",
                callback: () => {
                  props.softDeleteClient(props.client._id.toString());
                },
              };
              props.setTemp(temp);
            }}
            variant="contained"
            startIcon={<SoftDeleteIcon />}
            color="warning"
            size="small"
            disabled={props.client.deleted}
          >
            Soft Delete
          </Button>
        </Grid>
        */}
        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              var temp = Object.assign({}, props.temp);
              temp.confirm = {
                title: "Are you sure?",
                cancel_text: "No, Cancel",
                confirm_text: "Yes, Delete",
                callback_color: "error",
                text: "Do you want to delete this client?",
                callback: () => {
                  props.deleteClient(props.client._id.toString());
                },
              };
              props.setTemp(temp);
            }}
            variant="contained"
            color="error"
            size="small"
            style={{
              color: "#ffffff",
            }}
            title="Delete Client"
          >
            <DeleteIcon />
          </Fab>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Client);
