import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";

export default function ToggleButtons(props) {
  const [color, setColor] = React.useState(props.color);

  React.useEffect(() => {
    if (props.color !== color) {
      setColor(props.color);
    }
  }, [props.color, color]);

  const chooseColor = (event, newColor) => {
    if (newColor && newColor.length) {
      props.setColor(newColor);
    }
  };

  return (
    <div>
      <ToggleButtonGroup
        value={color}
        exclusive
        onChange={chooseColor}
        aria-label="label color"
        size="large"
        style={{ height: "48px" }}
      >
        <Tooltip title="None">
          <ToggleButton
            value="none"
            aria-label="none"
            size="large"
            sx={{
              width: "48px",
              backgroundColor: "transparent",
              marginLeft: "0px !important",
              "&.Mui-selected": {
                backgroundColor: "transparent",
                //borderColor: "#ffffff",
                border: "2px solid #ffffff",
                borderLeft: "2px solid #ffffff !important",
                marginLeft: "0px !important",
              },
              "&:hover": {
                backgroundColor: "transparent",
                //borderColor: "#ffffff",
                border: "2px solid #ffffff",
                borderLeft: "2px solid #ffffff !important",
                marginLeft: "0px !important",
              },
            }}
          />
        </Tooltip>
        <Tooltip title="Bad">
          <ToggleButton
            value="red"
            aria-label="red"
            size="large"
            sx={{
              width: "48px",
              backgroundColor: "#ff0000",
              marginLeft: "10px !important",
              "&.Mui-selected": {
                backgroundColor: "#ff0000",
                //borderColor: "#ffffff",
                border: "2px solid #ffffff",
                borderLeft: "2px solid #ffffff !important",
                marginLeft: "10px !important",
              },
              "&:hover": {
                backgroundColor: "#ff0000 !important",
                //borderColor: "#ffffff",
                border: "2px solid #ffffff",
                borderLeft: "2px solid #ffffff !important",
                marginLeft: "10px !important",
              },
            }}
          />
        </Tooltip>
        <Tooltip title="Good">
          <ToggleButton
            value="green"
            aria-label="green"
            size="large"
            sx={{
              width: "48px",
              backgroundColor: "#00ff00",
              marginLeft: "10px !important",
              "&.Mui-selected": {
                backgroundColor: "#00ff00",
                //borderColor: "#ffffff",
                border: "2px solid #ffffff",
                borderLeft: "2px solid #ffffff !important",
                marginLeft: "10px !important",
              },
              "&:hover": {
                backgroundColor: "#00ff00 !important",
                //borderColor: "#ffffff",
                border: "2px solid #ffffff",
                borderLeft: "2px solid #ffffff !important",
                marginLeft: "10px !important",
              },
            }}
          />
        </Tooltip>
        <Tooltip title="Maybe">
          <ToggleButton
            value="blue"
            aria-label="blue"
            size="large"
            sx={{
              width: "48px",
              backgroundColor: "#0000ff",
              marginLeft: "10px !important",
              "&.Mui-selected": {
                backgroundColor: "#0000ff",
                //borderColor: "#ffffff",
                border: "2px solid #ffffff",
                borderLeft: "2px solid #ffffff !important",
                marginLeft: "10px !important",
              },
              "&:hover": {
                backgroundColor: "#0000ff !important",
                //borderColor: "#ffffff",
                border: "2px solid #ffffff",
                borderLeft: "2px solid #ffffff !important",
                marginLeft: "10px !important",
              },
            }}
          />
        </Tooltip>
        <Tooltip title="Review">
          <ToggleButton
            value="yellow"
            aria-label="yellow"
            size="large"
            sx={{
              width: "48px",
              backgroundColor: "#ffff00",
              marginLeft: "10px !important",
              "&.Mui-selected": {
                backgroundColor: "#ffff00",
                //borderColor: "#ffffff",
                border: "2px solid #ffffff",
                borderLeft: "2px solid #ffffff !important",
                marginLeft: "10px !important",
              },
              "&:hover": {
                backgroundColor: "#ffff00 !important",
                //borderColor: "#ffffff",
                border: "2px solid #ffffff",
                borderLeft: "2px solid #ffffff !important",
                marginLeft: "10px !important",
              },
            }}
          />
        </Tooltip>
        <Tooltip title="Edit">
          <ToggleButton
            value="magenta"
            aria-label="magenta"
            size="large"
            sx={{
              width: "48px",
              backgroundColor: "#ff00ff",
              marginLeft: "10px !important",
              "&.Mui-selected": {
                backgroundColor: "#ff00ff",
                //borderColor: "#ffffff",
                border: "2px solid #ffffff",
                borderLeft: "2px solid #ffffff !important",
                marginLeft: "10px !important",
              },
              "&:hover": {
                backgroundColor: "#ff00ff !important",
                //borderColor: "#ffffff",
                border: "2px solid #ffffff",
                borderLeft: "2px solid #ffffff !important",
                marginLeft: "10px !important",
              },
            }}
          />
        </Tooltip>
      </ToggleButtonGroup>
    </div>
  );
}
