import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import Night from "@mui/icons-material/Brightness2TwoTone";
import Day from "@mui/icons-material/WbSunnyTwoTone";
import UserIcon from "@mui/icons-material/Group";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import FolderIcon from "@mui/icons-material/FolderCopy";
import { connect } from "react-redux";
import { setMenu, setTheme, setAdmin, setClients } from "../actions";

const icon_toggle = true;

function TemporaryDrawer(props) {
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    props.setMenu(!props.menu);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {props.admin ? (
          <React.Fragment>
            <ListItem
              disablePadding
              onClick={() => {
                if (props.clients.data.length) {
                  props.setClients({ total: 0, data: [] });
                  navigate("clients");
                } else {
                  navigate("clients");
                }
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <UserIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={"Clients"} />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              onClick={() => {
                navigate("folders");
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <FolderIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={"Projects"} />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              onClick={() => {
                props.setAdmin(false);
                localStorage.removeItem("admin");
                navigate("/");
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <LogoutIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={"Log Out"} />
              </ListItemButton>
            </ListItem>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ListItem
              disablePadding
              onClick={() => {
                navigate("admin");
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <UserIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={"Log In"} />
              </ListItemButton>
            </ListItem>
          </React.Fragment>
        )}
        <ListItem
          disablePadding
          onClick={() => {
            navigate("/");
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItemButton>
        </ListItem>
        {!icon_toggle ? (
          <React.Fragment>
            <Divider />
            <ListItem
              disablePadding
              onClick={() => {
                props.setTheme(props.theme === "light" ? "dark" : "light");
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  {props.theme === "dark" ? (
                    <Day color="secondary" />
                  ) : (
                    <Night color="secondary" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={`Enable ${
                    props.theme === "light" ? "Night" : "Bright"
                  } Mode`}
                />
              </ListItemButton>
            </ListItem>
          </React.Fragment>
        ) : null}
      </List>
    </Box>
  );

  return (
    <Drawer
      anchor={"left"}
      open={props.menu}
      onClose={() => {
        props.setMenu(false);
      }}
    >
      {list("left")}
    </Drawer>
  );
}

const mapStateToProps = (state) => ({
  menu: state.menu,
  theme: state.theme,
  admin: state.admin,
  clients: state.clients,
});

const mapDispatchToProps = (dispatch) => ({
  setMenu: (menu) => dispatch(setMenu(menu)),
  setTheme: (theme) => dispatch(setTheme(theme)),
  setAdmin: (admin) => dispatch(setAdmin(admin)),
  setClients: (clients) => dispatch(setClients(clients)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemporaryDrawer);
