import React from "react";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setGallery } from "../actions";
import PT from "prop-types";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";

import "lightgallery.js/dist/css/lightgallery.css";
import axios from "axios";

import { useParams } from "react-router-dom";

function download(url: string, filename: string) {
  fetch(url)
    .then(function (n) {
      return n.blob();
    })
    .then(function (n) {
      return new Promise(function (t, e) {
        var r = new FileReader();
        r.onloadend = function () {
          return t(r.result);
        };
        r.onerror = e;
        r.readAsDataURL(n);
      });
    })
    .then(function (n) {
      var t = document.createElement("a");
      t.href = n;
      t.download = filename;
      t.click();
    });

  return false;
}

const PhotoItem = ({ image, thumb, group, file, folder }) => (
  <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{ display: "flex" }}>
    <LightgalleryItem
      src={image}
      thumb={thumb}
      group={group}
      subHtml={`<div class="sub-details">${file}</div>`}
      downloadUrl={"/remote/files/push?file=" + image}
    >
      <div style={{ position: "relative", display: "flex" }}>
        <img
          alt=""
          src={image}
          style={{ width: "100%", minWidth: "235px" }}
          crossOrigin="anonymous"
        />
      </div>
      <div
        style={{
          backgroundColor: "#00000075",
          position: "absolute",
          bottom: 0,
          padding: "4px",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
          height: "40px",
          width: "100%",
          textAlign: "center",
        }}
      >
        {file}
      </div>
    </LightgalleryItem>
  </Grid>
);
PhotoItem.propTypes = {
  image: PT.string.isRequired,
  thumb: PT.string,
  group: PT.string.isRequired,
};

function App(props) {
  const theme = useTheme();

  let { folder, client } = useParams();

  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    var load = async () => {
      setLoaded(true);

      var root_gallery = props.protected ? "protected" : "gallery";

      axios
        .get("/photos/" + root_gallery + "/" + client + "/" + folder)
        .then((result) => {
          var new_gallery = Object.assign({}, props.gallery);
          new_gallery.documents = result.data.documents;

          props.setGallery(new_gallery);
        })
        .catch((err) => {});
    };

    if (!loaded) {
      load();
    }
  }, [props, loaded, folder, client]);

  return (
    <LightgalleryProvider
      lightgallerySettings={{
        mode: "lg-fade",
      }}
      onBeforeOpen={() => console.info("onBeforeOpen")}
      onAfterOpen={() => {
        var p = document.getElementById("lg-download").getAttribute("href");
        document
          .getElementById("lg-download")
          .setAttribute(
            "download",
            document.getElementById("lg-download").getAttribute("href")
          );
        document.getElementById("lg-download").onclick = (e) => {
          e.preventDefault();
          download(p, p.split("/")[p.split("/").length - 1]);
        };
      }}
      onSlideItemLoad={() => {
        var p = document.getElementById("lg-download").getAttribute("href");
        document
          .getElementById("lg-download")
          .setAttribute(
            "download",
            document.getElementById("lg-download").getAttribute("href")
          );
        document.getElementById("lg-download").onclick = (e) => {
          e.preventDefault();
          download(p, p.split("/")[p.split("/").length - 1]);
        };
      }}
    >
      <Grid
        xs={12}
        container
        item
        spacing={2}
        flexDirection="row"
        style={{ marginTop: 0, marginBottom: theme.spacing(2) }}
      >
        {props.gallery.documents.map((p, idx) => {
          return (
            <PhotoItem
              group="gallery"
              key={idx}
              file={
                p.data.imageURL.split("/")[
                  p.data.imageURL.split("/").length - 1
                ]
              }
              folder={
                p.data.imageURL.split("/")[
                  p.data.imageURL.split("/").length - 2
                ]
              }
              image={p.data.imageURL}
            />
          );
        })}
      </Grid>
    </LightgalleryProvider>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  alert: state.alert,
  gallery: state.gallery,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setGallery: (gallery) => dispatch(setGallery(gallery)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
