import React from "react";
//import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  setTemp,
  setImage,
  setTags,
  setTemplate,
  setAlltags,
} from "../actions";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CopyIcon from "@mui/icons-material/ContentCopy";
import PasteIcon from "@mui/icons-material/ContentPaste";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import TemplateIcon from "@mui/icons-material/Difference";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import SaveTemplate from "./SaveTemplate";

const _tags = {
  "By-line": "",
  "By-lineTitle": "",
  "Caption-Abstract": "",
  Headline: "",
  CopyrightNotice: "",
  Credit: "",
  DateCreated: "",
  TimeCreated: "",
  City: "",
  "Province-State": "",
  "Country-PrimaryLocationName": "",
  SpecialInstructions: "",
};

const useStyles = makeStyles((theme) => ({
  button: {
    "&:hover": {
      color: theme.palette.highlight.main,
    },
  },
}));

function Confirm(props) {
  const [templates, setTemplates] = React.useState([]);
  //const [folderTemplate, setFolderTemplate] = React.useState("");
  const [tags, setTags] = React.useState(_tags);
  var classes = useStyles();
  var fields = [];
  const [savingTemplate, setSavingTemplate] = React.useState(false);

  const changeTemplate = (e) => {
    //setFolderTemplate(e.target.value);
    templates.forEach((t, i) => {
      if (t._id === e.target.value) {
        setTags(t.tags);
      }
    });
    props.setSaved(false);
  };

  React.useEffect(() => {
    if (props.image.image.iptc) {
      var _temporary_tags = Object.assign({}, _tags);

      Object.keys(props.image.image.iptc).forEach((t, i) => {
        _temporary_tags[t] = props.image.image.iptc[t];
      });
      setTags(_temporary_tags);
    } else {
      setTags(_tags);
    }

    axios
      .post("/remote/folders/templates", {
        folder: props.folderInfo._id,
      })
      .then((result) => {
        setTemplates(result.data.templates);
      })
      .catch((err) => {});
  }, [props.image, props.folderInfo._id]);

  Object.keys(tags).forEach((k, i) => {
    if (k === "DateCreated") {
      var _field = (
        <Grid
          item
          key={i}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              var _temp_tags = Object.assign({}, props.tags);
              _temp_tags[k] =
                props.image?.image?.iptc && props.image?.image?.iptc[k]
                  ? props.image?.image?.iptc[k]
                  : tags[k];
              props.setTags(_temp_tags);
            }}
            variant="contained"
            size="small"
            color="button"
            className={classes.button}
          >
            <CopyIcon />
          </IconButton>
          <TextField
            fullWidth
            size="small"
            placeholder={k}
            value={
              props.image?.image?.iptc && props.image?.image?.iptc[k]
                ? props.image?.image?.iptc[k]
                : tags[k]
            }
            type="date"
            onChange={(e) => {
              var _temp = Object.assign({}, tags);
              _temp[k] = e.target.value;
              setTags(_temp);
              props.setSaved(false);
            }}
          />
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              var _temp = Object.assign({}, tags);
              _temp[k] = props.tags[k]
                ? props.tags[k]
                : props.alltags[k]
                ? props.alltags[k]
                : "";
              setTags(_temp);
            }}
            variant="contained"
            size="small"
            color="button"
            className={classes.button}
          >
            <PasteIcon />
          </IconButton>
        </Grid>
      );
    } else if (k === "TimeCreated") {
      _field = (
        <Grid
          item
          key={i}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              var _temp_tags = Object.assign({}, props.tags);
              _temp_tags[k] =
                props.image?.image?.iptc && props.image?.image?.iptc[k]
                  ? props.image?.image?.iptc[k]
                  : tags[k];
              props.setTags(_temp_tags);
            }}
            variant="contained"
            size="small"
            color="button"
            className={classes.button}
          >
            <CopyIcon />
          </IconButton>
          <TextField
            type="time"
            fullWidth
            size="small"
            placeholder={k}
            value={
              props.image?.image?.iptc && props.image?.image?.iptc[k]
                ? props.image?.image?.iptc[k]
                : tags[k]
            }
            onChange={(e) => {
              var _temp = Object.assign({}, tags);
              _temp[k] = e.target.value;
              setTags(_temp);
              props.setSaved(false);
            }}
          />
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              var _temp = Object.assign({}, tags);
              _temp[k] = props.tags[k]
                ? props.tags[k]
                : props.alltags[k]
                ? props.alltags[k]
                : "";
              setTags(_temp);
            }}
            variant="contained"
            size="small"
            color="button"
            className={classes.button}
          >
            <PasteIcon />
          </IconButton>
        </Grid>
      );
    } else {
      _field = (
        <Grid
          item
          key={i}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              var _temp_tags = Object.assign({}, props.tags);
              _temp_tags[k] =
                props.image?.image?.iptc && props.image?.image?.iptc[k]
                  ? props.image?.image?.iptc[k]
                  : tags[k];
              props.setTags(_temp_tags);
            }}
            variant="contained"
            size="small"
            color="button"
            className={classes.button}
          >
            <CopyIcon />
          </IconButton>
          <TextField
            fullWidth
            size="small"
            placeholder={k}
            value={tags[k]}
            onChange={(e) => {
              var _temp = Object.assign({}, tags);
              _temp[k] = e.target.value;
              setTags(_temp);
              props.setSaved(false);
            }}
          />
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              var _temp = Object.assign({}, tags);
              _temp[k] = props.tags[k]
                ? props.tags[k]
                : props.alltags[k]
                ? props.alltags[k]
                : "";
              setTags(_temp);
            }}
            variant="contained"
            size="small"
            color="button"
            className={classes.button}
          >
            <PasteIcon />
          </IconButton>
        </Grid>
      );
    }
    fields.push(_field);
  });

  var save_template = (templateName) => {
    axios
      .post("/remote/folders/savetemplate", {
        template: {
          tags: tags,
          name: templateName,
        },
        folder: props.folderInfo._id,
      })
      .then((result) => {
        setTemplates(result.data.templates);
        setSavingTemplate(false);
      })
      .catch((err) => {});
  };

  return (
    <React.Fragment>
      <Grid container spacing={1} style={{ marginTop: 0 }}>
        <Grid item xs={12} container>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItem: "center",

              alignContent: "center",
            }}
          >
            <IconButton
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                props.setAlltags(tags);
              }}
              variant="contained"
              size="small"
              color="button"
              className={classes.button}
            >
              <CopyIcon />
            </IconButton>
            <IconButton
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                setTags(props.alltags);
              }}
              variant="contained"
              size="small"
              color="button"
              className={classes.button}
            >
              <PasteIcon />
            </IconButton>
            <IconButton
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                setSavingTemplate(true);
              }}
              variant="contained"
              size="small"
              color="success"
              className={classes.button}
            >
              <TemplateIcon />
            </IconButton>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <FormControl
              sx={{
                "& .MuiSvgIcon-root": {
                  color:
                    props.theme === "dark"
                      ? "#ffffff !important"
                      : "#000000 !important",
                },
              }}
            >
              <Select
                fullWidth
                value={""}
                onChange={changeTemplate}
                variant="outlined"
                size="small"
                displayEmpty
                renderValue={() => {
                  return "Apply Template";
                }}
              >
                {templates.map((t, i) => {
                  return (
                    <MenuItem key={i} value={t._id}>
                      {t.name}
                    </MenuItem>
                  );
                })}
                {!templates.length ? (
                  <MenuItem key="none" value="" disabled>
                    No Templates
                  </MenuItem>
                ) : null}
              </Select>
            </FormControl>
            <IconButton
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => {
                console.log("TAGS");
                console.log(tags);
                props.tagSelected(tags, [props.image.image._id]);
              }}
              variant="contained"
              size="small"
              color="success"
              className={classes.button}
            >
              <SaveIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} spacing={1} container>
          {fields}
        </Grid>
      </Grid>
      {savingTemplate ? (
        <SaveTemplate
          save_template={save_template}
          savingTemplate={savingTemplate}
          setSavingTemplate={setSavingTemplate}
        />
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
  image: state.image,
  tags: state.tags,
  template: state.template,
  alltags: state.alltags,
  theme: state.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
  setImage: (image) => dispatch(setImage(image)),
  setTags: (tags) => dispatch(setTags(tags)),
  setTemplate: (template) => dispatch(setTemplate(template)),
  setAlltags: (alltags) => dispatch(setAlltags(alltags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
