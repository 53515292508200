import React from "react";
import { Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import Home from "./views/Home";
import Users from "./views/Users";
import Clients from "./views/clients/Clients";
import Folders from "./views/folders/Folders";
import ClientFolders from "./views/folders/ClientFolders";
import Alert from "./views/Alert";
import Gallery from "./views/Gallery";
import LightBox from "./views/LightBox";
import Admin from "./views/Admin";
import Reset from "./views/Reset";
import Editor from "./views/Editor";
import Viewer from "./views/Viewer";
import Uploader from "./modules/Files/Uploader";

function Routing(props) {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/admin" index exact element={<Admin />} />
        <Route path="/" index exact element={<Home />} />
        <Route path="/users" index exact element={<Users />} />

        <Route
          path="/manage/uploader/:folder"
          index
          exact
          element={<Uploader />}
        />

        <Route path="/client/:client" index exact element={<ClientFolders />} />

        <Route
          path="/gallery/:client/:folder"
          index
          exact
          element={<Gallery />}
        />
        <Route
          path="/folder/:client/:folder"
          index
          exact
          element={<LightBox />}
        />
        <Route
          path="/share/:folder"
          index
          exact
          element={<LightBox protected={true} />}
        />
        <Route
          path="/share/:client/:folder"
          index
          exact
          element={<Viewer protected={true} />}
        />

        <Route
          path="/view/:folder"
          index
          exact
          element={<LightBox protected={true} />}
        />
        <Route
          path="/view/:client/:folder"
          index
          exact
          element={<Viewer protected={true} />}
        />

        <Route
          path="/share/:client/:folder/:edit"
          index
          exact
          element={<Editor protected={true} />}
        />
        <Route
          path="/view/:client/:folder/:download"
          index
          exact
          element={<Editor protected={true} viewer={true} />}
        />
        <Route path="/gallery/:folder" index exact element={<Gallery />} />
        <Route path="/folder/:folder" index exact element={<LightBox />} />
        {props.admin ? (
          <React.Fragment>
            <Route path="/clients" index exact element={<Clients />} />
            <Route path="/folders" index exact element={<Folders />} />
            <Route path="/reset" index exact element={<Reset />} />
          </React.Fragment>
        ) : null}
      </Routes>
      <Alert alert={props.alert} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  alert: state.alert,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
