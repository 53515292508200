import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import { connect } from "react-redux";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { setTemp, setClient } from "../../actions";

function ComboBox(props) {
  const theme = useTheme();

  const [inputValue, setInputValue] = React.useState("");

  var clientMap = props.clients.data.map((c, i) => {
    return {
      label: c.name,
      _id: c._id,
    };
  });

  React.useEffect(() => {
    if (
      props.showAdd &&
      props.temp.inputValue &&
      props.temp.inputValue !== inputValue
    ) {
      setInputValue(props.temp.inputValue);

      var _temp = Object.assign({}, props.temp);
      delete _temp.inputValue;
      props.setTemp(_temp);
    }

    if (
      props.showAdd &&
      props.temp.client?.label &&
      props.temp.client?.label !== inputValue
    ) {
      setInputValue(props.temp.client.label);
    }
  }, [props, inputValue]);

  return (
    <Grid container>
      <Grid
        item
        style={{ display: "flex", flexDirection: "row" }}
        container
        spacing={2}
      >
        <Grid item xs={12} sm="auto">
          <Autocomplete
            fullWidth={props.fullWidth}
            disabled={props.disabled}
            disableClearable={props.disableClearable}
            size="small"
            isOptionEqualToValue={(option, value) => {
              if (option && option._id && value && value._id) {
                if (option._id === value._id) {
                  return true;
                }
              } else {
                return false;
              }
            }}
            freeSolo={true}
            getOptionLabel={(option) => {
              //console.log(option)
              return option.label ? option.label : "";
            }}
            disablePortal
            PaperComponent={({ children }) => (
              <Paper style={{ background: theme.palette.dropdown.main }}>
                {children}
              </Paper>
            )}
            id="select-client"
            options={clientMap}
            sx={{
              width: "100%",
              minWidth: "235px",

              label: { color: theme.palette.secondary.main },
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option._id}>
                  {option.label}
                </li>
              );
            }}
            renderInput={(params) => {
              if (props.is_folder) {
                params.InputLabelProps = {
                  shrink: true,
                };
              }

              return (
                <TextField fullWidth placeholder="Choose Client" {...params} />
              );
            }}
            value={props.selectedClient ? props.selectedClient : null}
            onChange={(event, newValue) => {
              var st = props.setTemp;

              var _temp = Object.assign({}, props.temp);
              _temp.client = newValue;
              st(_temp);

              //props.setClient(newValue);

              if (!newValue) {
                props.searchFolders(null, null, null, true);
                props.setClient(null);
              }

              if (props.update) {
                props.update(newValue);
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              if (props.showAdd) {
                props.setFolderValue(newInputValue);
              }
            }}
          />
        </Grid>
        {props.showAdd && inputValue && !props.selectedClient && 1 === 2 ? (
          <Grid item>
            <Button
              size="medium"
              variant="contained"
              color="success"
              onClick={() => {
                props.addClient(inputValue);
                //setInputValue("");
              }}
            >
              Create
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  clients: state.clients,
  temp: state.temp,
  client: state.client,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
  setClient: (client) => dispatch(setClient(client)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComboBox);
