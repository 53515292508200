import React from "react";
import Button from "@mui/material/Button";
//import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { setTemp } from "../actions";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

const _tags = {
  "By-line": "",
  "By-lineTitle": "",
  "Caption-Abstract": "",
  Headline: "",
  CopyrightNotice: "",
  Credit: "",
  DateCreated: "",
  TimeCreated: "",
  City: "",
  "Province-State": "",
  "Country-PrimaryLocationName": "",
  SpecialInstructions: "",
};

function Confirm(props) {
  const [tags, setTags] = React.useState(_tags);
  const [templates, setTemplates] = React.useState([]);
  const [newTemplate, setNewTemplate] = React.useState(false);
  const [newTitle, setNewTitle] = React.useState("");
  const handleClose = () => {
    props.setTagging(false);
  };

  const changeTemplate = (e) => {
    //setFolderTemplate(e.target.value);

    if (e.target.value === "new") {
      setNewTemplate(true);
    } else {
      templates.forEach((t, i) => {
        if (t._id === e.target.value) {
          setTags(t.tags);
        }
      });
    }
  };

  var save_template = (templateName) => {
    axios
      .post("/remote/folders/savetemplate", {
        template: {
          tags: tags,
          name: templateName,
        },
        folder: props.folderInfo._id,
      })
      .then((result) => {
        setTemplates(result.data.templates);
      })
      .catch((err) => {});
  };

  React.useEffect(() => {
    axios
      .post("/remote/folders/templates", {
        folder: props.folderInfo._id,
      })
      .then((result) => {
        setTemplates(result.data.templates);
      })
      .catch((err) => {});
  }, [props.folderInfo]);

  if (!props.tagging) {
    return null;
  }

  var fields = [];

  Object.keys(tags).forEach((k, i) => {
    if (k === "DateCreated") {
      var _field = (
        <Grid item key={i} xs={12}>
          <TextField
            fullWidth
            placeholder={k}
            value={tags[k]}
            type="date"
            onChange={(e) => {
              var _temp = Object.assign({}, tags);
              _temp[k] = e.target.value;
              setTags(_temp);
            }}
          />
        </Grid>
      );
    } else if (k === "TimeCreated") {
      _field = (
        <Grid item key={i} xs={12}>
          <TextField
            type="time"
            fullWidth
            placeholder={k}
            value={tags[k]}
            onChange={(e) => {
              var _temp = Object.assign({}, tags);
              _temp[k] = e.target.value;
              setTags(_temp);
            }}
          />
        </Grid>
      );
    } else {
      _field = (
        <Grid item key={i} xs={12}>
          <TextField
            fullWidth
            placeholder={k}
            value={tags[k]}
            onChange={(e) => {
              var _temp = Object.assign({}, tags);
              _temp[k] = e.target.value;
              setTags(_temp);
            }}
          />
        </Grid>
      );
    }
    fields.push(_field);
  });

  return (
    <Dialog
      open={props.tagging}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
      scroll="paper"
    >
      <DialogTitle style={{ paddingBottom: "8px" }}>Edit IPTC data</DialogTitle>
      <DialogContent style={{ paddingTop: "0px" }}>
        <Grid container item xs={12}>
          <FormControl
            fullWidth
            sx={{
              "& .MuiSvgIcon-root": {
                color:
                  props.theme === "dark"
                    ? "#ffffff !important"
                    : "#000000 !important",
              },
              mb: 1,
            }}
          >
            <Select
              fullWidth
              value={""}
              onChange={changeTemplate}
              variant="outlined"
              size="small"
              displayEmpty
              renderValue={() => {
                return "Apply Template";
              }}
            >
              <MenuItem key={"new"} value={"new"}>
                New
              </MenuItem>
              {templates.map((t, i) => {
                return (
                  <MenuItem key={i} value={t._id}>
                    {t.name}
                  </MenuItem>
                );
              })}
              {!templates.length ? (
                <MenuItem key="none" value="" disabled>
                  No Templates
                </MenuItem>
              ) : null}
            </Select>
          </FormControl>
        </Grid>

        <Grid container spacing={1}>
          {newTemplate ? (
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                placeholder={"New Template Name"}
                value={newTitle}
                onChange={(e) => {
                  setNewTitle(e.target.value);
                }}
              />
            </Grid>
          ) : null}

          {fields}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            props.callback(tags);
            if (newTitle) {
              save_template(newTitle);
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
  theme: state.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
