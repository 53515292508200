import React from "react";
import { connect } from "react-redux";
import { setAdmin, setAlert } from "../actions";
import Grid from "@mui/material/Grid";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Fade from "@mui/material/Fade";

function Admin(props) {
  const navigate = useNavigate();

  const handleClose = () => {
    setNotify({ type: "off" });
  };

  const theme = useTheme();
  const [password, setPassword] = React.useState("");
  const [notify, setNotify] = React.useState({ type: "off" });

  const login = (e) => {
    if (e) {
      e.preventDefault();
    }

    axios
      .post("/remote/users/admin", {
        password: password,
      })
      .then((result) => {
        if (result.data.allowed) {
          props.setAdmin(true);
          localStorage.setItem("admin", true);
          navigate("/folders");
        } else {
          setNotify({ type: "error", message: "invalid log in" });
        }
      })
      .catch((err) => {
        setNotify({ type: "error", message: "error logging in" });
      });
  };

  return (
    <React.Fragment>
      <Grid
        xs={12}
        container
        item
        spacing={1}
        flexDirection="row"
        style={{ marginTop: theme.spacing(2), justifyContent: "center" }}
      >
        <Grid item xs={12} sm="auto">
          <form onSubmit={login}>
            <TextField
              autoComplete="new-password"
              type="password"
              sx={{
                input: {
                  "&::placeholder": {
                    opacity: 1,
                  },
                },
                "& fieldset": {
                  borderColor: theme.palette.secondary.dark,
                },
                "& .MuiOutlinedInput-root:hover": {
                  "& > fieldset": {
                    borderColor: theme.palette.secondary.main,
                  },
                },
                label: { color: theme.palette.secondary.main },
              }}
              color="secondary"
              variant="outlined"
              value={password}
              placeholder={"password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              size="small"
              fullWidth
            />
          </form>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              login();
            }}
          >
            Log In
          </Button>
        </Grid>
      </Grid>
      {notify.type !== "off" ? (
        <Snackbar
          open={notify !== "off"}
          autoHideDuration={2000}
          onClose={handleClose}
          TransitionComponent={Fade}
          message={notify.message}
          key={Fade.name}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={notify.type ? notify.type : "info"}
            sx={{ width: "100%" }}
          >
            {notify.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  admin: state.admin,
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  setAdmin: (admin) => dispatch(setAdmin(admin)),
  setAlert: (alert) => dispatch(setAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
