import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { connect } from "react-redux";
import { setGallery } from "../../../actions";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}`,
  };
}

function ImagesList(props) {
  var documents = props.gallery.documents ? props.gallery.documents : [];

  return (
    <SimpleReactLightbox>
      <SRLWrapper options={{ settings: { downloadedFileName: "download" } }}>
        <ImageList variant="quilted" cols={4} rowHeight={200}>
          {documents.map((item, index) => (
            <ImageListItem
              key={item?.id}
              cols={
                pattern[
                  index - Math.floor(index / pattern.length) * pattern.length
                ].cols
              }
              rows={
                pattern[
                  index - Math.floor(index / pattern.length) * pattern.length
                ].rows
              }
              sx={{
                opacity: ".7",
                transition: "opacity .3s linear",
                cursor: "pointer",
                "&:hover": { opacity: 1 },
              }}
            >
              {item?.data?.thumbnailURL ? (
                <a href={item?.data?.imageURL}>
                  <img
                    {...srcset(
                      item?.data?.thumbnailURL,
                      200,
                      pattern[
                        index -
                          Math.floor(index / pattern.length) * pattern.length
                      ].rows,
                      pattern[
                        index -
                          Math.floor(index / pattern.length) * pattern.length
                      ].cols
                    )}
                    alt={item?.data?.uName || item?.data?.uEmail?.split("@")[0]}
                    loading="lazy"
                    crossOrigin="anonymous"
                  />
                </a>
              ) : (
                <img
                  {...srcset(
                    item?.data?.imageURL,
                    200,
                    pattern[
                      index -
                        Math.floor(index / pattern.length) * pattern.length
                    ].rows,
                    pattern[
                      index -
                        Math.floor(index / pattern.length) * pattern.length
                    ].cols
                  )}
                  alt={item?.data?.uName || item?.data?.uEmail?.split("@")[0]}
                  loading="lazy"
                  crossOrigin="anonymous"
                />
              )}
            </ImageListItem>
          ))}
        </ImageList>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
}

const pattern = [
  {
    rows: 2,
    cols: 2,
  },
  {
    rows: 1,
    cols: 1,
  },
  {
    rows: 1,
    cols: 1,
  },
  {
    rows: 1,
    cols: 2,
  },
  {
    rows: 1,
    cols: 2,
  },
  {
    rows: 2,
    cols: 2,
  },
  {
    rows: 1,
    cols: 1,
  },
  {
    rows: 1,
    cols: 1,
  },
];

const mapStateToProps = (state) => ({
  gallery: state.gallery,
});

const mapDispatchToProps = (dispatch) => ({
  setGallery: (gallery) => dispatch(setGallery(gallery)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagesList);
