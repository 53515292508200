import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";
import { setAlert } from "../actions";
import Fade from "@mui/material/Fade";

function Alerts(props) {
  const [open, setOpen] = React.useState(props.alert.open);

  React.useEffect(() => {
    setOpen(props.alert.open);
  }, [props.alert.open]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    props.setAlert({
      open: false,
    });
  };

  return open ? (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
      TransitionComponent={Fade}
      message={props.alert.message}
      key={"alerted"}
    >
      <MuiAlert
        variant="filled"
        onClose={handleClose}
        severity={props.alert.severity}
        sx={{ width: "100%" }}
      >
        {props.alert.message}
      </MuiAlert>
    </Snackbar>
  ) : null;
}

const mapStateToProps = (state) => ({
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  setAlert: (alert) => dispatch(setAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
