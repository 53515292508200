import React from "react";
import Button from "@mui/material/Button";
//import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { setTemp } from "../actions";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { LoadingButton } from "@mui/lab";
import FTPIcon from "@mui/icons-material/MoveUp";

const _details = {
  host: "",
  username: "",
  password: "",
  port: "",
  path: "/",
  mode: "passive",
  secure: "normal",
};

function Confirm(props) {
  const [details, setDetails] = React.useState(_details);

  const handleClose = () => {
    props.setTransferring(false);
  };

  const handleChange = (e) => {
    var _new = Object.assign({}, details);
    _new.mode = e.target.value;
    setDetails(_new);
    localStorage.setItem("details", JSON.stringify(_new));
  };

  const handleSecure = (e) => {
    var _new = Object.assign({}, details);
    _new.secure = e.target.value;
    setDetails(_new);
    localStorage.setItem("details", JSON.stringify(_new));
  };

  React.useEffect(() => {
    var __details = localStorage.getItem("details");

    if (__details) {
      setDetails(JSON.parse(__details));
    }
  }, []);

  if (!props.transferring) {
    return null;
  }

  var fields = [];

  Object.keys(details).forEach((k, i) => {
    if (k === "mode") {
      var _field = (
        <Grid item key={i} xs={12}>
          <ToggleButtonGroup
            color="success"
            value={details.mode}
            exclusive
            onChange={handleChange}
            aria-label="Mode"
          >
            <ToggleButton
              value="passive"
              sx={{ opacity: details.mode !== "passive" ? 0.5 : 1 }}
            >
              Passive
            </ToggleButton>
            <ToggleButton
              value="active"
              sx={{ opacity: details.mode !== "active" ? 0.5 : 1 }}
            >
              Active
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      );
    } else if (k === "secure") {
      _field = (
        <Grid item key={i} xs={12}>
          <ToggleButtonGroup
            color="success"
            value={details.secure}
            exclusive
            onChange={handleSecure}
            aria-label="Secure"
          >
            <ToggleButton
              value={"normal"}
              sx={{ opacity: details.secure !== "normal" ? 0.5 : 1 }}
            >
              Normal
            </ToggleButton>
            <ToggleButton
              value={"secure"}
              sx={{ opacity: details.secure !== "secure" ? 0.5 : 1 }}
            >
              Secure
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      );
    } else {
      _field = (
        <Grid item key={i} xs={12}>
          <TextField
            fullWidth
            type={k === "password" ? "password" : "text"}
            placeholder={k}
            value={details[k]}
            onChange={(e) => {
              var _temp = Object.assign({}, details);
              _temp[k] = e.target.value;
              setDetails(_temp);
              localStorage.setItem("details", JSON.stringify(_temp));
            }}
          ></TextField>
        </Grid>
      );
    }
    fields.push(_field);
  });

  return (
    <Dialog
      open={props.transferring}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
      scroll="paper"
    >
      <DialogTitle style={{ paddingBottom: "8px" }}>FTP Upload</DialogTitle>
      <DialogContent style={{ paddingTop: "0px" }}>
        <Grid container spacing={1}>
          {fields}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>

        <LoadingButton
          onClick={() => {
            props.callback(details);
          }}
          variant="contained"
          size="small"
          loading={props.uploading}
          loadingPosition="start"
          startIcon={<FTPIcon />}
          sx={{ ml: 1, mt: 1 }}
        >
          Save & Upload
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
