import { createTheme } from "@mui/material/styles";
import secondaryColor from "@mui/material/colors/grey";
import dangerColor from "@mui/material/colors/orange";
import errorColor from "@mui/material/colors/red";
import { darken } from "@mui/material/styles";

var shadows = [];
for (var i = 0; i < 25; i++) {
  shadows[i] = "none";
}

const lightTheme = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: secondaryColor[500],
            color: "#ffffff",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
        input: {
          "&::placeholder": {
            opacity: 0.75,
            textOverflow: "ellipsis !important",
            color: secondaryColor[500],
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          //boxShadow: "none",
          boxShadow:
            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
          borderRadius: "0px !important",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          "&::-webkit-scrollbar": {},
          "&::-webkit-scrollbar-thumb": {},
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0px !important",
        },
        contained: {
          color: "#ffffff",
        },
        containedPrimary: {
          color: "#ffffff",
        },
        containedSecondary: {
          color: "#ffffff",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          colorScheme: "light",
        },
        "*": {
          "&::-webkit-scrollbar": {},
          "&::-webkit-scrollbar-track": {},
          "&::-webkit-scrollbar-thumb": {},
          "&::-webkit-scrollbar-thumb:hover": {},
        },

        "& .MuiOutlinedInput-root": {
          "& > fieldset": {
            borderColor: secondaryColor[500],
          },
        },

        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            borderColor: secondaryColor[500] + " !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#000000 !important",
          },
        },

        ".Mui-focused": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#000000 !important",
          },
        },
      },
    },
  },
  shadows: shadows,
  palette: {
    type: "light",
    error: {
      light: errorColor[300],
      main: errorColor[400],
      dark: errorColor[700],
      contrastText: darken(errorColor[500], 0.3),
    },
    danger: {
      light: dangerColor[300],
      main: dangerColor[400],
      dark: dangerColor[700],
      contrastText: darken(dangerColor[500], 0.3),
    },
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
    custom: {
      light: secondaryColor[300],
      main: "#000000",
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    dropdown: {
      light: secondaryColor[300],
      main: "#ffffff",
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    sub: {
      light: secondaryColor[300],
      main: secondaryColor[500],
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    secondary: {
      light: secondaryColor[300],
      main: secondaryColor[500],
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    cards: {
      light: secondaryColor[300],
      main: "#ffffff",
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    background: {
      default: "#efefef",
    },
    bright: {
      light: secondaryColor[300],
      main: "#ffffff",
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    button: {
      light: secondaryColor[300],
      main: "#e6e6e6",
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    highlight: {
      light: secondaryColor[300],
      main: darken(secondaryColor[500], 0.5),
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
  },
});

export default lightTheme;
