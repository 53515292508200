import React from "react";
import Grid from "@mui/material/Grid";
//import ShareIcon from "@mui/icons-material/Share";
import ApproveIcon from "@mui/icons-material/CheckOutlined";
import LinkIcon from "@mui/icons-material/Link";
import Fab from "@mui/material/Fab";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setTemp, setAlert } from "../../actions";

function Share(props) {
  var navigate = useNavigate();
  return (
    <React.Fragment>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Grid item xs={4}>
          <Fab
            onClick={() => {
              if (navigator.share) {
                navigator
                  .share({
                    title: props.folder.name,
                    text: "Shared Gallery",
                    url:
                      process.env.REACT_APP_BASE +
                      "/view/" +
                      props.folder.client.uuid +
                      "/" +
                      props.folder.uuid,
                  })
                  .then(() => console.log("Successful share"))
                  .catch((error) => console.log("Error sharing", error));
              } else {
                navigate(
                  "/view/" + props.folder.client.uuid + "/" + props.folder.uuid
                );

                var temp = Object.assign({}, props.temp);
                temp.confirm = null;
                props.setTemp(temp);
              }
            }}
            variant="contained"
            size="small"
            color="info"
            title="Display Share Options"
            disabled={props.folder.deleted || !props.folder.client}
          >
            <LinkIcon />
          </Fab>
        </Grid>
        <Grid item xs={8}>
          View Only
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
        }}
        sx={{ mt: 2 }}
      >
        <Grid item xs={4}>
          <Fab
            onClick={() => {
              if (navigator.share) {
                navigator
                  .share({
                    title: props.folder.name,
                    text: "Shared Gallery",
                    url:
                      process.env.REACT_APP_BASE +
                      "/view/" +
                      props.folder.client.uuid +
                      "/" +
                      props.folder.uuid +
                      "/" +
                      props.folder.download_pin,
                  })
                  .then(() => console.log("Successful share"))
                  .catch((error) => console.log("Error sharing", error));
              } else {
                navigate(
                  "/view/" +
                    props.folder.client.uuid +
                    "/" +
                    props.folder.uuid +
                    "/" +
                    props.folder.download_pin
                );
                var temp = Object.assign({}, props.temp);
                temp.confirm = null;
                props.setTemp(temp);
              }
            }}
            variant="contained"
            size="small"
            color="info"
            title="Display Share Options"
            disabled={props.folder.deleted || !props.folder.client}
          >
            <DownloadIcon />
          </Fab>
        </Grid>
        <Grid item xs={8}>
          View Only With Download
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
        }}
        sx={{ mt: 2 }}
      >
        <Grid item xs={4}>
          <Fab
            onClick={() => {
              if (navigator.share) {
                navigator
                  .share({
                    title: props.folder.name,
                    text: "Shared Gallery",
                    url:
                      process.env.REACT_APP_BASE +
                      "/share/" +
                      props.folder.client.uuid +
                      "/" +
                      props.folder.uuid +
                      "/" +
                      props.folder.edit_pin,
                  })
                  .then(() => {})
                  .catch((error) => console.log("Error sharing", error));
              } else {
                navigate(
                  "/share/" +
                    props.folder.client.uuid +
                    "/" +
                    props.folder.uuid +
                    "/" +
                    props.folder.edit_pin
                );
                var temp = Object.assign({}, props.temp);
                temp.confirm = null;
                props.setTemp(temp);
              }
            }}
            variant="contained"
            size="small"
            color="info"
            title="Display Share Options"
            disabled={props.folder.deleted || !props.folder.client}
          >
            <ApproveIcon />
          </Fab>
        </Grid>
        <Grid item xs={8}>
          With Approval
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
  setAlert: (alert) => dispatch(setAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Share);
