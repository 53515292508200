import React from "react";
import { connect } from "react-redux";
import {
  setUser,
  setAlert,
  setTemp,
  setGallery,
  setImage,
  setSelected,
} from "../actions";
import Grid from "@mui/material/Grid";
import { useTheme, Typography } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import Pages from "../components/Pages";
import Image from "./Image";
import ViewCard from "./ViewCard";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const CancelToken = axios.CancelToken;

let source = CancelToken.source();

function Editor(props) {
  const theme = useTheme();

  const [auto, setAuto] = React.useState(true);
  const [folderInfo, setFolderInfo] = React.useState(null);

  const handleAuto = (event) => {
    setAuto(event.target.checked);
  };

  React.useEffect(() => {
    return () => {
      if (window.auto) {
        clearTimeout(window.auto);
        delete window.auto;
      }
      if (window.old_title && window.old_title.length) {
        document.title = window.old_title;
      }
    };
  }, []);

  let { folder, client, edit, download } = useParams();

  const [loaded, setLoaded] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [search_term, setSearchTerm] = React.useState("");
  const [sort, setSort] = React.useState("datedown");

  if (!folder) {
    folder = "28d85c0a107e63a48241";
  }

  const [pagination, setPagination] = React.useState({
    page: 1,
    skip: 0,
    limit: 100,
    max: 100,
  });

  const handleSort = async (e) => {
    setSort(e.target.value);
    setLoaded(false);
  };

  const paginationSearch = async (pagination_settings) => {
    searchClients(null, pagination_settings);
  };

  const searchClients = (filter, pagination_settings) => {
    source.cancel("cancelled");
    source = CancelToken.source();

    if (!loading) {
      setLoading(true);
    }

    if (!pagination_settings) {
      pagination_settings = pagination;
    }

    axios
      .post("/photos/search", {
        query: {
          search_term: filter && filter === "all" ? "" : search_term,
          page: 0,
          pagination: pagination_settings,
        },
        deleted: true,
        client: client,
        folder: folder,
        edit_pin: edit,
        download_pin: download,
        sort: sort,
      })
      .then((result) => {
        if (result.data.folder) {
          setFolderInfo(result.data.folder);
        }

        if (!window.old_title) {
          window.old_title = document.title;
        }

        if (result.data.folder.name.length) {
          document.title = window.old_title + " | " + result.data.folder.name;
        }

        var new_gallery = Object.assign({}, props.gallery);
        new_gallery.documents = result.data.data;
        new_gallery.total = result.data.total;
        props.setGallery(new_gallery);

        if (filter && filter === "all") {
          setSearchTerm("");
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err && err.message === "cancelled") {
        } else {
          setLoading(false);
        }
      });
  };

  const deletePhoto = async (_id) => {
    await axios.post("/remote/files/deletephoto", {
      _id: _id,
    });
  };

  React.useEffect(() => {
    var load = async () => {
      if (loading) {
        return false;
      }

      source.cancel("cancelled");
      source = CancelToken.source();

      if (!loading) {
        setLoading(true);
      }

      var pagination_settings = pagination;

      axios
        .post("/photos/search", {
          query: {
            search_term: search_term,
            page: 0,
            pagination: pagination_settings,
          },
          deleted: true,
          client: client,
          folder: folder,
          edit_pin: edit,
          download_pin: download,
          sort: sort,
        })
        .then((result) => {
          if (result.data.folder) {
            setFolderInfo(result.data.folder);
          }

          window.old_title = document.title;
          if (
            result.data.folder.name.length &&
            result.data.folder.name !== "/"
          ) {
            document.title = document.title + " | " + result.data.folder.name;
          }
          var new_gallery = Object.assign({}, props.gallery);
          new_gallery.documents = result.data.data;
          new_gallery.total = result.data.total;

          //compare results current gallery and add new images while removing the same number of existing images

          props.setGallery(new_gallery);

          setLoading(false);
          setChecked(true);

          if (auto) {
            if (window.auto) {
              clearTimeout(window.auto);
            }
            window.auto = setTimeout(load, 15000);
          }
        })
        .catch((err) => {
          if (err && err.message === "cancelled") {
          } else {
            setLoading(false);

            if (auto) {
              if (window.auto) {
                clearTimeout(window.auto);
              }
              window.auto = setTimeout(load, 15000);
            }
          }
        });
    };

    if (!auto && window.auto) {
      clearTimeout(window.auto);
      delete window.auto;
    }

    if (!loaded) {
      setLoaded(true);
      setTimeout(load, 1);
    }
  }, [
    props,
    loaded,
    folder,
    client,
    loading,
    pagination,
    search_term,
    edit,
    sort,
    download,
    auto,
  ]);

  if (!checked) {
    return null;
  }

  if (checked && !props.gallery.total) {
    return (
      <React.Fragment>
        <Grid style={{ marginTop: 2 }}>
          {folderInfo ? (
            <Typography variant="h6">{folderInfo.name}</Typography>
          ) : null}
        </Grid>
        <Grid
          container
          item
          style={{ marginTop: 1, height: "48px" }}
          alignItems="center"
          flexDirection="row"
        >
          This gallery contains no images.
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Grid style={{ marginTop: 2 }}>
        {folderInfo ? <Typography>{folderInfo.name}</Typography> : null}
      </Grid>
      <Grid
        container
        item
        sx={{ mt: 1 }}
        alignItems="center"
        flexDirection="row"
      >
        <Grid item style={{ marginRight: 1 }}></Grid>

        <Grid item xs={12} container style={{ display: "flex", flex: 1 }}>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={auto}
                    onChange={handleAuto}
                    inputProps={{ "aria-label": "Auto Update" }}
                  />
                }
                label="Auto Update"
              />
            </FormGroup>
          </Grid>
          <Grid item style={{ flex: 1, textAlign: "right" }}>
            <FormControl
              sx={{
                "& .MuiSvgIcon-root": {
                  color:
                    props.theme === "dark"
                      ? "#ffffff !important"
                      : "#000000 !important",
                },
              }}
            >
              <Select
                value={sort}
                onChange={handleSort}
                variant="outlined"
                size="small"
              >
                <MenuItem value={"dateup"}>Date Added (asc)</MenuItem>
                <MenuItem value={"datedown"}>Date Added (desc)</MenuItem>
                <MenuItem value={"nameup"}>Name (asc)</MenuItem>
                <MenuItem value={"namedown"}>Name (desc)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        container
        item
        spacing={2}
        flexDirection="row"
        style={{ marginTop: 0, marginBottom: theme.spacing(2) }}
      >
        {props.gallery.documents.map((p, idx) => {
          var image = p.data.thumbnailURL;

          return (
            <Grid item xs={12} md={3} lg={2} key={idx}>
              <ViewCard
                key={p._id}
                approved={p.approved}
                image={image}
                img={p}
                deletePhoto={deletePhoto}
                block={download ? false : true}
                idx={idx}
                total={props.gallery.documents.length}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid xs={12} container item style={{ marginBottom: 1 }}>
        <Pages
          setPagination={setPagination}
          align="center"
          callback={paginationSearch}
          pagination={pagination}
          total={props.gallery && props.gallery.total ? props.gallery.total : 0}
        />
      </Grid>
      {props.image ? <Image /> : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  alert: state.alert,
  gallery: state.gallery,
  image: state.image,
  selected: state.selected,
  theme: state.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setGallery: (gallery) => dispatch(setGallery(gallery)),
  setImage: (image) => dispatch(setImage(image)),
  setSelected: (selected) => dispatch(setSelected(selected)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
