import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { setMenu, setTheme, setAdmin, setClients } from "../actions";
import { NavLink } from "react-router-dom";
import Night from "@mui/icons-material/Brightness2TwoTone";
import Day from "@mui/icons-material/WbSunnyTwoTone";
import UserIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import FolderIcon from "@mui/icons-material/FolderCopy";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

const icon_toggle = true;

const ResponsiveAppBar = (props) => {
  const handleOpenNavMenu = (event) => {
    props.setMenu(!props.menu);
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    var adminStored = localStorage.getItem("admin")
      ? localStorage.getItem("admin")
      : false;

    if (adminStored !== props.admin) {
      props.setAdmin(adminStored);
    }
  }, [props]);

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <img
            onClick={() => {
              navigate("/");
            }}
            alt="Le Studio Photography"
            src="/logo.png"
            style={{ height: "44px", marginLeft: "8px", cursor: "pointer" }}
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              variant="contained"
              to="/"
              component={NavLink}
              sx={{ my: 2, color: "white" }}
              startIcon={<HomeIcon color="secondary" />}
            >
              Home
            </Button>
            {props.admin ? (
              <React.Fragment>
                <Button
                  onClick={() => {
                    if (props.clients.data.length) {
                      props.setClients({ total: 0, data: [] });
                    }
                  }}
                  variant="contained"
                  to="clients"
                  component={NavLink}
                  sx={{ my: 2, color: "white" }}
                  startIcon={<UserIcon color="secondary" />}
                >
                  Clients
                </Button>
                <Button
                  variant="contained"
                  to="folders"
                  component={NavLink}
                  sx={{ my: 2, color: "white" }}
                  startIcon={<FolderIcon color="secondary" />}
                >
                  Projects
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    props.setAdmin(false);
                    localStorage.removeItem("admin");
                    navigate("/");
                  }}
                  sx={{ my: 2, color: "white" }}
                  startIcon={<LogoutIcon color="secondary" />}
                >
                  Log Out
                </Button>
              </React.Fragment>
            ) : (
              <Button
                variant="contained"
                to="admin"
                component={NavLink}
                xs={{ my: 2, color: "white" }}
                startIcon={<UserIcon color="secondary" />}
              >
                Log In
              </Button>
            )}
            {!icon_toggle ? (
              <Button
                onClick={() => {
                  props.setTheme(props.theme === "light" ? "dark" : "light");
                }}
                variant="contained"
                sx={{ my: 2, color: "white" }}
                startIcon={
                  props.theme === "dark" ? (
                    <Day color="secondary" />
                  ) : (
                    <Night color="secondary" />
                  )
                }
              >
                Enable {props.theme === "light" ? "Night" : "Bright"} Mode
              </Button>
            ) : null}
          </Box>
          <Box sx={{ flexGrow: 0, right: 0, position: "absolute" }}>
            <IconButton
              color="inherit"
              onClick={() => {
                props.setTheme(props.theme === "light" ? "dark" : "light");
              }}
            >
              {props.theme === "dark" ? <Day /> : <Night />}
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  menu: state.menu,
  theme: state.theme,
  admin: state.admin,
  clients: state.clients,
});

const mapDispatchToProps = (dispatch) => ({
  setMenu: (menu) => dispatch(setMenu(menu)),
  setTheme: (theme) => dispatch(setTheme(theme)),
  setAdmin: (admin) => dispatch(setAdmin(admin)),
  setClients: (clients) => dispatch(setClients(clients)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveAppBar);
