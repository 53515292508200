export const setUser = (user) => ({
  type: "SET_USER",
  user,
});

export const setTemp = (temp) => ({
  type: "SET_TEMP",
  temp,
});

export const setAlert = (alert) => ({
  type: "SET_ALERT",
  alert,
});

export const setImage = (image) => ({
  type: "SET_IMAGE",
  image,
});

export const setMeta = (meta) => ({
  type: "SET_META",
  meta,
});

export const setExif = (exif) => ({
  type: "SET_EXIF",
  exif,
});

export const setIptc = (iptc) => ({
  type: "SET_IPTC",
  iptc,
});

export const setSelected = (selected) => ({
  type: "SET_SELECTED",
  selected,
});

export const director = (to) => ({
  type: "DIRECTOR",
  to,
});

export const setTheme = (theme) => {
  return {
    type: "SET_THEME",
    theme,
  };
};

export const setMenu = (menu) => {
  return {
    type: "SET_MENU",
    menu,
  };
};

export const setGallery = (gallery) => {
  return {
    type: "SET_GALLERY",
    gallery,
  };
};

export const setClients = (clients) => {
  return {
    type: "SET_CLIENTS",
    clients,
  };
};

export const setClient = (client) => {
  return {
    type: "SET_CLIENT",
    client,
  };
};

export const setAdmin = (admin) => {
  return {
    type: "SET_ADMIN",
    admin,
  };
};

export const setTags = (tags) => {
  return {
    type: "SET_TAGS",
    tags,
  };
};

export const setTemplate = (template) => {
  return {
    type: "SET_TEMPLATE",
    template,
  };
};

export const setAlltags = (alltags) => {
  return {
    type: "SET_ALLTAGS",
    alltags,
  };
};
