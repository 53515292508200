import React from "react";
import Button from "@mui/material/Button";
//import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { setTemp } from "./actions";

function Confirm(props) {
  const handleClose = () => {
    if (props.temp.confirm && props.temp.confirm.prevent_close) {
      return false;
    }

    var temp = Object.assign({}, props.temp, {
      confirm: null,
    });

    if (props.temp.confirm.cancel) {
      props.temp.confirm.cancel();
    }

    props.setTemp(temp);
  };

  if (!props.temp.confirm) {
    return null;
  }

  return (
    <Dialog
      open={props.temp.confirm ? true : false}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
      scroll="paper"
    >
      <DialogTitle style={{ paddingBottom: "8px" }}>
        {props.temp.confirm && props.temp.confirm.title
          ? props.temp.confirm.title
          : ""}
      </DialogTitle>
      <DialogContent style={{ paddingTop: "0px" }}>
        {props.temp.confirm && props.temp.confirm.text
          ? props.temp.confirm.text
          : "Are you sure?"}
      </DialogContent>
      {props.temp.confirm && !props.temp.confirm.hide_buttons ? (
        <DialogActions>
          <Button
            onClick={handleClose}
            color={
              props.temp.confirm.cancel_color
                ? props.temp.confirm.cancel_color
                : "secondary"
            }
            variant="outlined"
          >
            {props.temp.confirm && props.temp.confirm.cancel_text
              ? props.temp.confirm.cancel_text
              : "Cancel"}
          </Button>

          {props.temp.confirm.hide_confirm ? null : (
            <Button
              variant="contained"
              onClick={() => {
                if (props.temp.confirm && props.temp.confirm.callback) {
                  handleClose();
                  props.temp.confirm.callback();
                }
              }}
              color={
                props.temp.confirm.callback_color
                  ? props.temp.confirm.callback_color
                  : "primary"
              }
            >
              {props.temp.confirm && props.temp.confirm.confirm_text
                ? props.temp.confirm.confirm_text
                : "Yes"}
            </Button>
          )}
        </DialogActions>
      ) : (
        <DialogActions></DialogActions>
      )}
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
