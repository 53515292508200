import React from "react";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { setTemp, setAlert } from "../../actions";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";

function Archive(props) {
  const [folder, setFolder] = React.useState(props.folder);

  const handleChange = async (e, _type) => {
    var _send = {};

    var _checked = e.target.checked;
    _send[_type] = _checked;

    await props.modifyFolder(props.folder._id, _send);

    var _new = Object.assign({}, folder);
    _new[_type] = !_new[_type];

    setFolder(_new);

    var newFolders = props.folders.data.slice();

    var _newFolders = newFolders.map((v, i) => {
      if (v._id.toString() === props.folder._id.toString()) {
        v[_type] = _checked;
      }
      return v;
    });

    //console.log(_newFolders);

    props.setFolders({ total: props.folders.total, data: _newFolders });

    //localStorage.setItem("details", JSON.stringify(_new));
  };

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiSwitch-track": {
      backgroundColor: "#39393D",
      opacity: 1,
    },
  }));

  return (
    <React.Fragment>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Grid item xs={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={folder.archive}
                  onChange={(e) => {
                    handleChange(e, "archive");
                  }}
                  inputProps={{ "aria-label": "Edit" }}
                  color="success"
                />
              }
              //label="Stop Sweeping"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={8}>
          Archive Folder
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Grid item xs={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={folder.stop}
                  onChange={(e) => {
                    handleChange(e, "stop");
                  }}
                  inputProps={{ "aria-label": "Edit" }}
                  color="success"
                />
              }
              //label="Stop Sweeping"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={8}>
          Stop Sweeping
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Grid item xs={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={folder.disable_links}
                  onChange={(e) => {
                    handleChange(e, "disable_links");
                  }}
                  inputProps={{ "aria-label": "Edit" }}
                  color="success"
                />
              }
              //label="Stop Sweeping"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={8}>
          Disable Links
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
  setAlert: (alert) => dispatch(setAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Archive);
