import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/DeleteForever";
//import SoftDeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import Typography from "@mui/material/Typography";
import SaveIcon from "@mui/icons-material/Save";
import ShareIcon from "@mui/icons-material/Share";
//import LinkIcon from "@mui/icons-material/Link";
//import SweepIcon from "@mui/icons-material/Refresh";
import UploadIcon from "@mui/icons-material/Upload";
import { connect } from "react-redux";
import { setTemp, setAlert } from "../../actions";
import SelectClient from "../clients/Select";
import Fab from "@mui/material/Fab";
import ApproveIcon from "@mui/icons-material/PhotoLibraryOutlined";
import { useNavigate } from "react-router-dom";
import Share from "./Share";
import Archive from "./Archive";
//import EyeIcon from "@mui/icons-material/Visibility";
import useMediaQuery from "@mui/material/useMediaQuery";

function Folder(props) {
  const [folderName, setFolderName] = React.useState(props.folder.name);
  const [client, setClient] = React.useState(props.folder.client);
  const [clientUUID, setClientUUID] = React.useState(
    props.folder.client ? props.folder.client.uuid : null
  );

  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  React.useEffect(() => {
    setFolderName(props.folder.name);
  }, [props.folder, props.clients]);

  var disabled = props.folder.name === "home" && !props.folder.cient;

  var rowSx = {};

  if (!isMobile) {
    rowSx = { flex: 1 };
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={"auto"}>
          <SelectClient
            disabled={disabled || !client}
            is_folder={true}
            disableClearable={true}
            setClient={setClient}
            clients={props.clients}
            selectedClient={client}
            update={(newValue) => {
              setClientUUID(newValue._id);
              props.updateFolder(props.folder._id, folderName, newValue._id);
            }}
          />
        </Grid>
        <Grid item xs={12} sm="auto" style={rowSx}>
          <TextField
            style={{
              width: "100%",
            }}
            color="secondary"
            variant="outlined"
            value={folderName}
            placeholder="folder name"
            onChange={(e) => {
              setFolderName(e.target.value);
            }}
            size="small"
            disabled={props.folder.deleted || disabled}
            sx={{
              "& .MuiOutlinedInput-input": {
                WebkitTextFillColor:
                  props.folder.deleted || disabled ? "#414141" : "none",
              },
            }}
          />
        </Grid>
        {/*
        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              window.open("/share/" + clientUUID + "/" + props.folder.uuid);
            }}
            variant="contained"
            size="small"
            color="info"
            disabled={props.folder.deleted || !client}
            title="View Gallery Page"
          >
            <LinkIcon />
          </Fab>
        </Grid>
                */}

        {/*
          <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              props.clients.data.forEach((c, i) => {
                if (c.uuid === clientUUID) {
                  navigate("/client/" + c._id);

                }
              });
            }}
            variant="contained"
            size="small"
            color="info"
            disabled={props.folder.deleted || !client}
            title="View All Folders"
          >
            <EyeIcon />
          </Fab>
        </Grid>
          */}

        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              navigate(
                "/share/" +
                  clientUUID +
                  "/" +
                  props.folder.uuid +
                  "/" +
                  props.folder.edit_pin
              );
            }}
            variant="contained"
            size="small"
            color="info"
            disabled={props.folder.deleted || !client}
            title="View Approval Page"
          >
            <ApproveIcon />
          </Fab>
        </Grid>

        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              var temp = Object.assign({}, props.temp);
              temp.confirm = {
                title: "Share this folder",
                text: <Share folder={props.folder} />,
                hide_confirm: true,
                cancel_text: "Close",
              };
              props.setTemp(temp);
            }}
            variant="contained"
            size="small"
            color="info"
            title="Display Share Options"
            disabled={props.folder.deleted || !client}
          >
            <ShareIcon />
          </Fab>
        </Grid>
        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              var with_uuid = props.getLabel({ client: client._id });

              setClientUUID(with_uuid.uuid);

              if (!folderName) {
                props.setAlert({
                  open: true,
                  severity: "error",
                  message: "folder name is required",
                });
                return false;
              }

              props.updateFolder(props.folder._id, folderName, client._id);
            }}
            variant="contained"
            size="small"
            color="success"
            title="Save Folder"
            disabled={props.folder.deleted || disabled || !client}
          >
            <SaveIcon />
          </Fab>
        </Grid>
        {/*
        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              var temp = Object.assign({}, props.temp);
              temp.confirm = {
                title: "Are you sure?",
                cancel_text: "No, Cancel",
                confirm_text: "Yes, Delete",
                callback_color: "warning",
                text: "Do you want to soft delete this folder?",
                callback: () => {
                  props.softDeleteFolder(props.folder._id.toString());
                },
              };
              props.setTemp(temp);
            }}
            variant="contained"
            size="small"
            color="warning"
            disabled={props.folder.deleted}
          >
            <SoftDeleteIcon />
          </Fab>
        </Grid>
          */}

        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              var temp = Object.assign({}, props.temp);
              temp.confirm = {
                title: "Archive this folder",
                text: (
                  <Archive
                    setFolders={props.setFolders}
                    folder={props.folder}
                    folders={props.folders}
                    modifyFolder={props.modifyFolder}
                  />
                ),
                hide_confirm: true,
                cancel_text: "Close",
              };
              props.setTemp(temp);
            }}
            variant="contained"
            size="small"
            color="warning"
            disabled={disabled || !client}
            title="Archive Folder"
          >
            <ArchiveIcon
              style={{
                color: disabled ? "inherit" : "#ffffff",
              }}
            />
          </Fab>
        </Grid>

        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              var temp = Object.assign({}, props.temp);
              temp.confirm = {
                title: "Are you sure?",
                cancel_text: "No, Cancel",
                confirm_text: "Yes, Delete",
                callback_color: "error",
                text: "Do you want to delete this folder?",
                callback: () => {
                  props.deleteFolder(props.folder._id.toString());
                },
              };
              props.setTemp(temp);
            }}
            variant="contained"
            size="small"
            color="error"
            disabled={disabled || !client}
            title="Delete Folder"
          >
            <DeleteIcon
              style={{
                color: disabled ? "inherit" : "#ffffff",
              }}
            />
          </Fab>
        </Grid>
        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              //props.upload(props.folder._id.toString());
              navigate(
                "/manage/uploader/" +
                  props.folder._id.toString() +
                  "?title=" +
                  props.folder.name
              );
            }}
            variant="contained"
            size="small"
            color="warning"
            title="Upload Photos"
          >
            <UploadIcon />
          </Fab>
        </Grid>
        {/*
        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              props.sweep(props.folder._id.toString());
            }}
            variant="contained"
            size="small"
            color="warning"
            disabled={disabled}
            title="Sweep Folder"
          >
            <SweepIcon />
          </Fab>
        </Grid>
          */}
      </Grid>
      <Grid style={{ paddingTop: "4px" }}>
        <Typography variant="caption">
          FTP PATH: {props.folder.pin ? props.folder.pin : "1234"}
        </Typography>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
  alert: state.alert,
  clients: state.clients,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
  setAlert: (alert) => dispatch(setAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Folder);
