import React from "react";
import Button from "@mui/material/Button";
//import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { setTemp } from "../actions";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";

function Confirm(props) {
  const [cloneName, setCloneName] = React.useState("");
  const [cloneID, setCloneID] = React.useState("");
  const [folders, setFolders] = React.useState(null);

  const [pagination, setPagination] = React.useState({
    page: 1,
    skip: 0,
    limit: 20,
    max: 20,
  });

  const nameRef = React.useRef("");
  const IDRef = React.useRef("");

  const handleClose = () => {
    props.setCloning(false);
  };

  React.useEffect(() => {
    const _getLabel = (folder) => {
      var client_label = "";

      var findClient = props.clients.data.findIndex(
        (x) => x._id === folder.client
      );

      if (findClient > -1) {
        var found = props.clients.data[findClient];

        client_label = found.name;
        var newClient = {
          label: client_label,
          _id: found._id,
          uuid: found.uuid,
        };

        return newClient;
      }
    };

    axios
      .post("/remote/folders/search", {
        query: {
          search_term: "",
          page: 0,
          uuid: props.client,
          pagination: pagination,
        },
        deleted: true,
      })
      .then((results) => {
        var folder_map = results.data.data.map((f, i) => {
          var labeled = _getLabel(f);

          f.client = labeled;

          return f;
        });

        results.data.data = folder_map;

        setFolders(results.data.data);
      });
  }, [props.client, props.clients.data, pagination, props.folderInfo]);

  if (!props.cloning) {
    return null;
  }

  return (
    <Dialog
      open={props.cloning}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
      scroll="paper"
    >
      <DialogTitle style={{ paddingBottom: "8px" }}>Copy photos</DialogTitle>
      <DialogContent style={{ paddingTop: "0px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={nameRef.current ? nameRef.current : ""}
              onChange={(e) => {
                setCloneName(e.target.value);
                nameRef.current = e.target.value;
                window.cloneName = e.target.value;
                if (cloneID?.length) {
                  setCloneID("");
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              sx={{
                "& .MuiSvgIcon-root": {
                  color:
                    props.theme === "dark"
                      ? "#ffffff !important"
                      : "#000000 !important",
                },
                mb: 1,
              }}
            >
              <Select
                fullWidth
                value={cloneID}
                onChange={(e) => {
                  setCloneID(e.target.value);
                  window.cloneID = e.target.value;
                  if (cloneName?.length) {
                    setCloneName("");
                    nameRef.current = "";
                    window.cloneName = "";
                  }
                }}
                variant="outlined"
                size="small"
                displayEmpty
              >
                <MenuItem key="select" value="" disabled>
                  Select Folder
                </MenuItem>
                {folders?.map((f, i) => {
                  return (
                    <MenuItem key={i} value={f._id}>
                      {f.name}
                    </MenuItem>
                  );
                })}
                {!folders?.length ? (
                  <MenuItem key="none" value="" disabled>
                    No Folders
                  </MenuItem>
                ) : null}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            if (cloneName || !cloneName) {
              props.callback();
            }
          }}
        >
          Copy
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
  clients: state.clients,
  //client: state.client,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
