import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ShareIcon from "@mui/icons-material/Share";
//import LinkIcon from "@mui/icons-material/Link";
//import ApproveIcon from "@mui/icons-material/CheckOutlined";
import { connect } from "react-redux";
import { setTemp } from "../../actions";
import Fab from "@mui/material/Fab";
import Share from "./Share";

function Folder(props) {
  const [folderName, setFolderName] = React.useState(props.folder.name);
  const [client] = React.useState(props.folder.client);

  React.useEffect(() => {
    setFolderName(props.folder.name);
  }, [props.folder, props.clients]);

  console.log(client);

  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm="auto">
          FTP PATH / PIN: {props.folder.pin ? props.folder.pin : "1234"}
        </Grid>
        <Grid item xs={12} sm="auto">
          <TextField
            style={{
              width: "100%",
            }}
            color="secondary"
            variant="outlined"
            value={folderName}
            placeholder="folder name"
            onChange={(e) => {
              //setFolderName(e.target.value);
            }}
            size="small"
            disabled={props.folder.deleted}
          />
        </Grid>
        <Grid item xs={"auto"} sm={"auto"}>
          <Fab
            onClick={() => {
              var temp = Object.assign({}, props.temp);
              temp.confirm = {
                title: "Share this folder",
                text: <Share folder={props.folder} />,
                hide_confirm: true,
                cancel_text: "Close",
              };
              props.setTemp(temp);
            }}
            variant="contained"
            size="small"
            color="info"
            title="Display Share Options"
            disabled={props.folder.deleted || !client}
          >
            <ShareIcon />
          </Fab>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
  clients: state.clients,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Folder);
