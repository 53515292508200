import * as React from "react";
import TextField from "@mui/material/TextField";

function Comments(props) {
  const [comments, setComments] = React.useState(props.comments);

  React.useEffect(() => {
    setComments(props.comments);
  }, [props.comments]);

  return (
    <TextField
      style={{
        width: "100%",
      }}
      color="secondary"
      variant="outlined"
      value={comments}
      placeholder="Comments"
      multiline
      rows={10}
      onChange={(e) => {
        setComments(e.target.value);
        props.commentRef.current = e.target.value;
        props.setComments(e.target.value);
      }}
      size="small"
    />
  );
}

export default Comments;
