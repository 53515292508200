import React from "react";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setGallery } from "../actions";
import Grid from "@mui/material/Grid";
import Gallery from "./Viewer";
import Dashboard from "./Dashboard";

function Home(props) {
  var adminStored = localStorage.getItem("admin") ? true : false;

  return (
    <Grid
      xs={12}
      container
      item
      spacing={0}
      flexDirection="column"
      style={{ marginTop: 0 }}
    >
      <Grid item xs={12}>
        {adminStored ? <Dashboard /> : <Gallery show="home" block={true} />}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  alert: state.alert,
  gallery: state.gallery,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setGallery: (gallery) => dispatch(setGallery(gallery)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
