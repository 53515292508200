import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import SoftDeleteIcon from "@mui/icons-material/Delete";
import { connect } from "react-redux";
import { setTemp } from "../actions";
import Divider from "@mui/material/Divider";

function User(props) {
  const theme = useTheme();

  const [userName, setUserName] = React.useState(props.user.name);
  const [displayName, setDisplayName] = React.useState(props.user.displayName);

  React.useEffect(() => {
    setUserName(props.user.name);
    setDisplayName(props.user.displayName);
  }, [props.users, props.user]);

  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <TextField
            sx={
              props.user.deleted
                ? {}
                : {
                    input: {
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                    "& fieldset": {
                      borderColor: theme.palette.secondary.dark,
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: theme.palette.secondary.main,
                      },
                    },
                    label: { color: theme.palette.secondary.main },
                  }
            }
            color="secondary"
            variant="outlined"
            value={userName}
            label={"username"}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            size="small"
            disabled={props.user.deleted}
          />
        </Grid>
        <Grid item>
          <TextField
            sx={
              props.user.deleted
                ? {}
                : {
                    input: {
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                    "& fieldset": {
                      borderColor: theme.palette.secondary.dark,
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: theme.palette.secondary.main,
                      },
                    },
                    label: { color: theme.palette.secondary.main },
                  }
            }
            color="secondary"
            variant="outlined"
            value={displayName}
            label={"display name"}
            onChange={(e) => {
              setDisplayName(e.target.value);
            }}
            size="small"
            disabled={props.user.deleted}
          />
        </Grid>
        <Grid item xs={12} sm={"auto"}>
          <Button
            onClick={() => {
              props.updateUser(props.user._id, userName, displayName);
            }}
            variant="contained"
            size="medium"
            color="success"
            disabled={props.user.deleted}
          >
            Update User
          </Button>
        </Grid>
        <Grid item xs={12} sm={"auto"}>
          <Button
            onClick={() => {
              var temp = Object.assign({}, props.temp);
              temp.confirm = {
                title: "Are you sure?",
                cancel_text: "No, Cancel",
                confirm_text: "Yes, Delete",
                callback_color: "warning",
                text: "Do you want to soft delete this user?",
                callback: () => {
                  props.softDeleteUser(props.user._id.toString());
                },
              };
              props.setTemp(temp);
            }}
            variant="contained"
            startIcon={<SoftDeleteIcon />}
            color="warning"
            size="medium"
            disabled={props.user.deleted}
          >
            Soft Delete
          </Button>
        </Grid>
        <Grid item xs={12} sm={"auto"}>
          <Button
            onClick={() => {
              var temp = Object.assign({}, props.temp);
              temp.confirm = {
                title: "Are you sure?",
                cancel_text: "No, Cancel",
                confirm_text: "Yes, Delete",
                callback_color: "error",
                text: "Do you want to delete this user?",
                callback: () => {
                  props.deleteUser(props.user._id.toString());
                },
              };
              props.setTemp(temp);
            }}
            variant="contained"
            startIcon={<DeleteIcon />}
            color="error"
            size="medium"
          >
            Full Delete
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  temp: state.temp,
});

const mapDispatchToProps = (dispatch) => ({
  setTemp: (temp) => dispatch(setTemp(temp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
